<script setup lang="ts">
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { Segment } from '@/areas/editor/@type/Project'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import IconSaxSearchZoomIn from '@/components/Icons/iconsax/IconSaxSearchZoomIn.vue'
import { computed } from 'vue'
import { useEditorFocusStore } from "@/store/editor/editorFocus";
import { useEffectsStore } from "@/areas/editor/store/useEffectsStore";

const props = defineProps<{ id: string, index: number, zooms: Segment[] }>()

const segmentsStore = useSegmentsStore()
const zoom = segmentsStore.selectById(props.id)
const segments = segmentsStore.whereIsNotZoom()

const videoStore = useVideoStore()
const historyStore = useHistoryStore()

const { triggerSeek } = useTimelineStore()
function updateAndSeek(id: string, startMs: number, endMs: number) {

  if (startMs > videoStore.currentTime || endMs < videoStore.currentTime) {
    triggerSeek(startMs)
  }

  segmentsStore.updateTimingsById(id, startMs, endMs)
  historyStore.push()
}

const matchingSegments = computed(() => {
  return segments.value.filter((s) => {
    return zoom.startMs <= s.endMs && zoom.endMs >= s.startMs
  })
})

const disabled = computed(() => matchingSegments.value.length === 0)

const timingsGradient = computed(() => {

  const zoomDuration = zoom.endMs - zoom.startMs

  if (matchingSegments.value.length) {

    let gradient = 'linear-gradient(to right, var(--gray) 0%, '

    for (let i = 0; i < matchingSegments.value.length; i++) {

      const stop = matchingSegments.value[i]
      const start = Math.max(0, (stop.startMs - zoom.startMs) / zoomDuration * 100)
      const end = Math.min(100, (stop.endMs - zoom.startMs) / zoomDuration * 100)
      gradient += `var(--gray) ${start}%, var(--pink) ${start}%, var(--pink) ${end}%, var(--gray) ${end}%, `
    }

    gradient += `var(--gray) 100%)`

    return gradient
  } else {
    return null
  }
})

const isActiveZoom = computed(() => matchingSegments.value.length)
const previousZoom = computed(() => props.zooms[props.index - 1])
const nextZoom = computed(() => props.zooms[props.index + 1])

const editorFocusStore = useEditorFocusStore()
const effectsStore = useEffectsStore()

const effect = computed(() => {
  return effectsStore.entities.find(e => e.segmentId === props.id)
})

const hasFocus = computed(() => {
  return editorFocusStore.focus?.type === 'zoom' && effect.value?.id && editorFocusStore.focus?.key === effect.value.id
})

function setFocus() {

  const previousFocus = editorFocusStore.focus;
  editorFocusStore.setFocus('zoom', effect.value?.id)

  if (previousFocus?.key !== effect.value?.id) {
    videoStore._currentTime = zoom.startMs / 1000 + 0.01
  }
}
</script>

<template>
  <TimeLineTrackNode
    :id="zoom.id"
    class="zoom-node cursor-grab rounded-lg h-full hover:ring-4 data-[state=active]:ring-4 hover:z-20 data-[state=active]:z-10 transition-[box-shadow]"
    :class="disabled
      ? 'bg-gray-400 ring-gray-600'
      : 'bg-pink-600 ring-pink-700'"
    :key="zoom.id"
    :start-ms="zoom.startMs"
    :end-ms="zoom.endMs"
    :min-start-ms="previousZoom?.endMs ?? 0"
    :max-end-ms="nextZoom?.startMs ?? videoStore._duration * 1000"
    :active="Boolean(hasFocus)"
    @resizeStart="setFocus"
    @resizeStop="(startMs, endMs) => updateAndSeek(zoom.id, startMs, endMs)"
    @dragStart="setFocus"
    @dragStop="(startMs, endMs) => updateAndSeek(zoom.id, startMs, endMs)"
    @activated="setFocus"
  >
    <div class="flex h-full w-full items-center overflow-hidden rounded-lg text-sm font-normal text-white">
      <span class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-0.5 text-center align-middle font-normal">
        <IconSaxSearchZoomIn class="h-4 w-4 mb-0.5" />
        Zoom
      </span>
    </div>
    <template #handles>
      <div class="flex h-full flex-row gap-0.5 px-1 py-1">
        <div class="h-full w-1 rounded" :class="isActiveZoom ? 'bg-pink-700' : 'bg-gray-600'" />
        <div class="h-full w-1 rounded" :class="isActiveZoom ? 'bg-pink-700' : 'bg-gray-600'" />
      </div>
    </template>
  </TimeLineTrackNode>
</template>

<style scoped lang="scss">
  .zoom-node {
    --pink: theme('colors.pink.500');
    --gray: theme('colors.gray.200');
  }
</style>