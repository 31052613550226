<script lang="ts" setup>
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { FocusTypes, useEditorFocusStore, type FocusType } from '@/store/editor/editorFocus'
import { colorOptions, stringToSemiRandomNumber, removeTags } from '@/areas/editor/timeline/tracks/stickers/stickerHelpers'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxTextBlock from '@/components/Icons/iconsax/IconSaxTextBlock.vue'
import IconSaxSticker from '@/components/Icons/iconsax/IconSaxSticker.vue'
import { computed } from 'vue'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import type { Segment } from '@/areas/editor/@type/Project'

const props = defineProps<{ id: string, focusType: FocusType }>()

const editorFocusStore = useEditorFocusStore()
const videoStore = useVideoStore()

const stickersStore = useStickersStore()
const sticker = stickersStore.selectById(props.id)
const focused = stickersStore.selectFocusById(props.id)

const { durationMs } = useTimelineStore()

const semiRandomColor = computed(() => colorOptions[Math.floor(stringToSemiRandomNumber(sticker.id, 0, colorOptions.length))])

const segmentsStore = useSegmentsStore()
const segments = segmentsStore.whereIsNotZoom()

const isVisibleInAnySegment = computed(() => {
  return segments.value.some((segment: Segment) => {
    return sticker.startMs <= segment.endMs && sticker.endMs >= segment.startMs;
  });
});

const setFocus = () => {

  const previousFocus = editorFocusStore.focus;
  editorFocusStore.setFocus(props.focusType, sticker.id);

  if (previousFocus?.key !== sticker.id) {
    videoStore._currentTime = sticker.startMs / 1000 + 0.01;
  }
};
</script>

<template>
  <TimeLineTrackNode
    :id="sticker.id"
    :active="focused"
    :end-ms="sticker.endMs"
    :max-end-ms="durationMs"
    :min-start-ms="0"
    :start-ms="sticker.startMs"
    :style="{
      '--random-bg-color': semiRandomColor.bg,
      '--random-border-color': semiRandomColor.border,
      '--random-handle-color': semiRandomColor.handle,
    }"
    class="group cursor-grab rounded-lg h-full bg-[hsl(var(--random-bg-color))] ring-[hsl(var(--random-handle-color))] hover:ring-4 data-[state=active]:ring-4 hover:z-20 data-[state=active]:z-10 transition-[box-shadow]"
    :class="{ '!bg-gray-400 !ring-gray-600': !isVisibleInAnySegment }"
    seek-behavior="seek"
    @activated="setFocus"
    @dragStop="(startMs, endMs) => stickersStore.updateTimingsById(sticker.id, startMs, endMs)"
    @resizeStop="(startMs, endMs) => stickersStore.updateTimingsById(sticker.id, startMs, endMs)"
  >
    <div class="flex h-full w-full items-center overflow-hidden rounded-lg text-sm text-white">
      <span class="mx-3 inline-flex max-h-full w-full select-none flex-row items-center justify-center gap-1 overflow-hidden whitespace-nowrap py-0.5">

        <IconSaxTextBlock v-if="focusType === FocusTypes.TEXTSTICKER" class="mb-0.5 h-4 w-4" />
        <img v-else-if="focusType === FocusTypes.STICKER && sticker.imageUrl" :src="sticker.imageUrl" class="mb-0.5 h-9" alt="Image"/>
        <IconSaxSticker v-else class="mb-0.5 h-4 w-4" />

        <span v-if="'textContent' in sticker">{{ removeTags(sticker.textContent) }}</span>
      </span>
    </div>
    <template #handles>
      <div class="flex h-full flex-row gap-0.5 px-1 py-1">
        <div :class="sticker.key ? 'bg-[hsl(var(--random-handle-color))]' : 'bg-gray-800'" class="h-full w-1 rounded" />
        <div :class="sticker.key ? 'bg-[hsl(var(--random-handle-color))]' : 'bg-gray-800'" class="h-full w-1 rounded" />
      </div>
    </template>
  </TimeLineTrackNode>
</template>

<style lang="scss" scoped>

</style>
