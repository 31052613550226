import type { UniverseRenderer } from '@/modules/SLVideoplayer/canvas/UniverseRenderer'
import * as Sentry from '@sentry/browser'
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import type { MaybeRef } from '@vueuse/shared'
import type { VideoFragment } from '@/modules/SLVideoplayer/types'
import type { ColorSource } from 'pixi.js'
import { toValue, useEventListener } from '@vueuse/core'
import { WorkerRenderer } from '../canvas/WorkerRenderer'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'

export const useSLVideoRenderer = (
  videoElement: MaybeRef<HTMLVideoElement | null | undefined>,
  videoFragments: MaybeRef<VideoFragment[]>,
  options: {
    initialBackground?: MaybeRef<HTMLVideoElement | HTMLImageElement | null | undefined>
    backgroundColor?: ColorSource
    initialWidth?: number
    initialHeight?: number
  } = {}
) => {
  const renderer = ref<UniverseRenderer | WorkerRenderer>()
  const renderTarget = ref<HTMLDivElement>()

  const error = ref<string>()
  try {
  } catch (e) {
    error.value = 'Something went wrong while initializing the video preview. Please try using a different browser.'
    Sentry.captureException(e)
  }

  const initialized = ref(false)
  watch([renderTarget, renderer], () => {
    if (!renderTarget.value) return
    if (!renderer.value) return
    if (initialized.value) return
    initialized.value = true
    renderer.value.resizeTo(renderTarget.value)
    renderTarget.value.appendChild(renderer.value.view)
  })

  const background = ref(options.initialBackground || null)

  const setBackground = () => {
    if (background.value) {
      renderer.value?.setBackground(background.value)
    } else {
      renderer.value?.removeVideo('background')
    }
    nextTick(() => {
      renderer.value?.ticker.update()
    })
  }
  watch(background, setBackground)

  const updateFragments = (fragments: VideoFragment[]) => {
    fragments.forEach((fragment) => {
      // if (isNaN(fragment.feedData?.x) && isNaN(fragment.feedData?.y)) return
      if (!fragment.source) {
        fragment.source = toValue(videoElement)
      }
      renderer.value?.setVideo(fragment.key, fragment)
    })
  }

  onMounted(async () => {
    try {
      renderer.value = new WorkerRenderer(
        options.initialWidth || 0,
        options.initialHeight || 0,
        options.backgroundColor,
      );
      nextTick(() => {
        updateFragments(videoFragments.value || [])
        setBackground()
      })
    } catch (e) {
      console.error(e);
      error.value = 'Failed to initialize video renderer, please reload or try another browser or device'
      Sentry.captureException(e)
    }
  })

  watch(videoFragments, (fragments) => {
    if (!fragments) return
    updateFragments(fragments)
    if (!toValue(videoElement).playing) renderer.value?.ticker.update()
  })

  onBeforeUnmount(() => {
    renderer.value?.destroy()
  })

  useEventListener(videoElement, 'canplay', () => {
    if (!toValue(videoElement)) return
    if (!toValue(renderer)) return

    if (background.value) {
      renderer.value?.setBackground(background.value)
    }
    updateFragments(videoFragments.value || [])
    renderer.value?.ticker.update()
  })

  useEventListener(videoElement, 'loadeddata', () => {
    renderer.value?.resizeTo(renderTarget.value)
  })

  // useEventListener(videoElement, 'seeked', () => {
  //   renderer.value?.pixiApp.ticker.
  //   // renderer.value?.pixiApp.render()
  // })

  useEventListener(videoElement, 'play', () => {
    renderer.value?.ticker.start()
  })

  useEventListener(videoElement, 'pause', () => {
    renderer.value?.ticker.pause()
  })

  return {
    renderer,
    loaded: false,
    error,
    background,
    renderTarget,
    updateFragments,
  }
}
