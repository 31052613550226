<template>


  <div
    v-if="captionWizardStep === 'select'"
    class="layer-2 flex max-h-full flex-col rounded-lg border-2 border-gray-100 bg-white"
    data-retain-focus
  >
    <!-- Add Captions Banner-->
    <div class="flex flex-col p-4">
      <h2 class="text-xl font-semibold leading-snug">AI-Powered Captions</h2>
      <p class="font-light">Select a caption style below and customize it to your preferences after adding captions.</p>
    </div>
    <CaptionStyleSelector @generate-captions="generateCaptions" />
  </div>

  <CaptionPreviewPanel
    v-else-if="captionWizardStep === 'style'"
    @done="() => generateCaptions(captionEditorStore.captionStyle)"
    @back="captionWizardStep = 'select'"
  ></CaptionPreviewPanel>

  <CaptionEditPanel v-else-if="captionWizardStep === 'done'" />

  <CaptionGenerateDialog ref="captionGenerateDialog" @captionsFileContent="setCaptionsFileContent" />
</template>

<script lang="ts" setup>
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import CaptionGenerateDialog from '@/components/Captions/CaptionGenerateDialog.vue'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import type { CaptionsDocument } from '@/components/Captions/captionTypes'
import { computed, ref } from 'vue'
import { markCaptionAsUsed } from '@/helpers/isNewOverlayElement'
import type { CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import CaptionStyleSelector from '@/components/Captions/CaptionStyleSelector.vue'
import CaptionEditPanel from '@/components/Captions/CaptionEditPanel.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import CaptionPreviewPanel from "@/components/Captions/CaptionPreviewPanel.vue";

const captionEditorStore = useEditorCaptionsStore()
const editorFocusStore = useEditorFocusStore()

type CaptionWizardStep = 'select' | 'style' | 'done' | 'generating'

const captionWizardStep = ref<CaptionWizardStep>(captionEditorStore.captionsDocument ? 'done' :'select')

const startWizard = (style: CaptionStyle) => {
  captionWizardStep.value = 'style'
  captionEditorStore.captionStyle = style
}

const captionGenerateDialog = ref<typeof CaptionGenerateDialog | null>(null)
const setCaptionsFileContent = (captionsFileContent: CaptionsDocument) => {
  captionEditorStore.setCaptionsDocument(captionsFileContent)
  editorFocusStore.setFocus(FocusTypes.CAPTION)
  captionWizardStep.value = 'done'
}

const generateCaptions = (captionStyle: CaptionStyle) => {

  if (captionWizardStep.value === 'select' && captionEditorStore.noDefaultOptions) {
    startWizard(captionStyle)
    return
  }

  markCaptionAsUsed(captionStyle)
  editorVideoStore.playing = false
  captionEditorStore.captionStyle = captionStyle
  captionGenerateDialog.value?.open()
}

const editorVideoStore = useEditorVideoStore()
</script>
