<script setup lang="ts">
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import SoundNode from '@/areas/editor/timeline/tracks/sounds/SoundNode.vue'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { computed, ref, watch } from 'vue'
import { v4 as uuid } from 'uuid'
import type { SoundEffect } from '@/areas/editor/@type/Project'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'

const effectsStore = useEffectsStore();

const sounds = computed(() => {
  return effectsStore.entities.filter((e) => e.type === 'sound');
});

const isOverlapping = (stickerA, stickerB) => {
  if (stickerA.id === stickerB.id) {
    return false;
  } else {
    const overlapStart = Math.max(stickerA.startMs, stickerB.startMs);
    const overlapEnd = Math.min(stickerA.endMs, stickerB.endMs);
    const overlapDuration = overlapEnd - overlapStart;
    return overlapDuration > 5;
  }
};

const assignStickersToTracks = () => {

  const tracks = [];

  for (const sound of sounds.value) {

    let targetTrack = tracks.find((track) => {
      return !track.sounds.some((trackSticker) => isOverlapping(trackSticker, sound));
    });

    if (!targetTrack) {
      targetTrack = { id: uuid(), sounds: [] };
      tracks.push(targetTrack);
    }

    targetTrack.sounds.push(sound);
  }

  return tracks;
};

const timelineTracks = computed(() => assignStickersToTracks());

const hasDrawnWaveFormOnceForUrls = ref<string[]>([]);
const waveformsPerUrl = ref<{ soundUrl: string, waveformUrl: string }[]>([]);

const { msToPx } = useTimelineStore();

const requestDrawWaveForm = async (sound: SoundEffect) => {

  // Short-circuit if waveform has already been drawn for this sound
  if (hasDrawnWaveFormOnceForUrls.value.includes(sound.url)) return;

  hasDrawnWaveFormOnceForUrls.value = [...hasDrawnWaveFormOnceForUrls.value, sound.id];

  const canvas = document.createElement('canvas');
  canvas.width = msToPx(sound.maxDurationMs);
  canvas.height = 30;

  const audioContext = new AudioContext();
  const response = await fetch(sound.url);
  const arrayBuffer = await response.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  const canvasContext = canvas.getContext('2d');
  if (!canvasContext) return;

  // Set canvas dimensions and colors
  canvasContext.clearRect(0, 0, canvas.width, canvas.height);
  canvasContext.fillStyle = 'rgba(255,255,255,0.69)';

  // Average all available channels
  const numChannels = audioBuffer.numberOfChannels;
  const dataLength = audioBuffer.length;
  const averagedData = new Float32Array(dataLength);

  for (let channel = 0; channel < numChannels; channel++) {
    const channelData = audioBuffer.getChannelData(channel);
    for (let i = 0; i < dataLength; i++) {
      averagedData[i] += channelData[i] / numChannels;
    }
  }

  // Calculate steps and amplitude scaling
  const step = Math.ceil(averagedData.length / canvas.width); // Samples per pixel
  const amp = canvas.height / 2; // Amplitude scaling

  // Draw the waveform based on averaged data
  for (let i = 0; i < canvas.width; i++) {
    const segment = averagedData.slice(i * step, (i + 1) * step);
    const min = Math.min(...segment);
    const max = Math.max(...segment);

    // Center the waveform vertically by adding `amp`
    canvasContext.fillRect(i, amp * (1 - max), 1, Math.max(1, (max - min) * amp));
  }

  waveformsPerUrl.value = [
    ...waveformsPerUrl.value,
    {
      soundUrl: sound.url,
      waveformUrl: canvas.toDataURL(),
    },
  ];
};
</script>

<template>
  <TimeLineTrack
    v-for="track in timelineTracks"
    :key="'track_sounds_'+track.id"
    class="relative h-full !min-h-8 w-full"
  >
    <SoundNode
      v-for="(sound, i) in track.sounds"
      :key="sound.id"
      :index="i"
      :id="sound.id"
    />
  </TimeLineTrack>
</template>
