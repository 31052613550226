<script setup lang="ts">
import MultiUploadDialog from '@/components/Dialog/MultiUploadDialog/MultiUploadDialog.vue'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useStartup } from '@/areas/editor/startup'

const { startupFromLocalFileByClipId } = useStartup()
async function onSelect(clip: { id: string, title: string, videoUrl: string }) {

  const resetEditor = useResetEditor()
  resetEditor()

  await startupFromLocalFileByClipId(clip.id)
}
</script>

<template>
  <MultiUploadDialog call-to-action="Start editing" @select="onSelect" upload-in-background :max="10">
    <slot />
  </MultiUploadDialog>
</template>

<style scoped lang="scss">

</style>
