<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import { CardContent, CardHeader, Card, CardTitle, CardDescription, CardFooter } from '@/components/ui/card'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import { SliderRange, SliderRoot, SliderThumb, SliderTrack } from 'radix-vue'
import TikTokThumbnailPreview from '@/areas/dashboard/components/ScheduledPosts/form/TikTokThumbnailPreview.vue'
import { computed } from 'vue'
import SaveableTextarea from '@/components/SaveableTextarea/SaveableTextarea.vue'

const props = defineProps<{
  description: string
  coverMs: number
  maxMs: number
  videoAdded: boolean
  errors?: Record<string, { code: string; message: string }[]>
}>();

const emit = defineEmits(['update:title', 'update:coverMs']);

const _description = useVModel(props, 'description', emit);
const _coverMS = useVModel(props, 'coverMs', emit);

const ms = computed<[number]>({
  get: () => [_coverMS.value],
  set: (value: [number]) => {
    _coverMS.value = value[0]
  },
})

const _maxMs = computed(() => props.maxMs * 1000 || 100)
</script>

<template>
  <Card>
    <CardHeader>
      <CardTitle>Instagram Description</CardTitle>
      <CardDescription>Add a catchy caption, story or fun facts to spark interest</CardDescription>
    </CardHeader>
    <CardContent>
      <label class="relative block">
        <span class="absolute left-3 top-2.5">
          <IconSaxWarning2 v-if="errors?.['instagram,description']" class="h-5 w-5 text-error" />
          <InstagramIcon class="h-5 w-5" />
        </span>
      </label>
      <SaveableTextarea
        v-model="_description"
        placeholder="Instagram Description"
        :class="{ 'textarea-error': errors?.['instagram,description'] }"
        :maxTextLength="2200"
        tag="instagram"
        class="pl-10 pr-28"
      />
    </CardContent>
    <CardFooter>
      <span v-for="error of errors?.['instagram,description'] ?? []" :key="error.code" class="text-brand-state-error-text">
        {{ error.message }}
      </span>
    </CardFooter>

    <template v-if="videoAdded">
      <CardHeader class="pt-0">
        <CardTitle>Instagram thumbnail</CardTitle>
        <CardDescription>Choose a thumbnail. Slide until you find the right spot!</CardDescription>
      </CardHeader>
      <CardContent>
        <div class="flex items-center gap-2">
          <TikTokThumbnailPreview :currentTime="ms[0] / 1000" />
          <SliderRoot
            v-model="ms"
            class="cursor-pointer relative flex h-5 w-full touch-none select-none items-center group"
            :max="_maxMs"
            :step="1"
          >
            <SliderTrack class="relative h-[3px] grow rounded-full bg-zinc-400 group-hover:bg-zinc-500 transition-all">
              <SliderRange class="absolute h-full rounded-full bg-transparent" />
            </SliderTrack>
            <SliderThumb
              class="hover:bg-zinc-950 block h-5 w-5 rounded-[10px] bg-zinc-800 dark:bg-zinc-950 shadow-[0_2px_10px] shadow-zinc-700 focus:outline-none transition-colors"
              aria-label="Volume"
            />
          </SliderRoot>
        </div>
      </CardContent>
      <CardFooter>
        <span v-for="error of errors?.['instagram,videoCoverTimestampMs'] ?? []" :key="error.code" class="text-brand-state-error-text pb-8">
          {{ error.message }}
        </span>
      </CardFooter>
    </template>
  </Card>
</template>
