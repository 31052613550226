import EventBus from '../eventBus'
import authEvents from '../events/authEvents'
import { tiers } from '@/enums/tiers'
import upgradeEvents from '../events/upgradeEvents'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useSaleStore } from '@/store/sale'
import { getInterval, getTier, useProductsStore } from '@/store/products'
import { markRaw } from 'vue'
import { useCreateSubscription } from '@/Hooks/useCreateSubscription'
import {
  getApiSubscriptionBillingInfo,
  getApiSubscriptionGeneratePaymentLinkV2
} from '@/apis/streamladder-accounts/subscription/subscription'
import * as Sentry from '@sentry/vue'
import mainEvents from '@/events/mainEvents'
import { accountsAxios } from '@/services/axios'
import { getSession } from '@/authentication/supabase'

export default {
  methods: {
    upgradeable(tier) {
      const userInfoStore = useUserInfoStore()
      return tier > userInfoStore.tier
    },
    downgradable(tier) {
      const userInfoStore = useUserInfoStore()
      return tier < userInfoStore.tier
    },
    gold() {
      const productsStore = useProductsStore()
      return productsStore.productData.gold.tier
    },
    silver() {
      const productsStore = useProductsStore()
      return productsStore.productData.silver.tier
    },
    getBadgeComponent(key) {
      const productsStore = useProductsStore()

      if (typeof key === 'number') {
        key = this.tierNumberToDisplayString(key).toLowerCase()
      }

      if (!productsStore.productData[key]) {
        console.error(`${key} is not an available plan`)
        return null
      }
      return markRaw(productsStore.productData[key].badge)
    },
    tierNumberToDisplayString(num) {
      return tiers.toString(num)
    },
    async attemptCheckout(productId, discountCode, reason) {

      const userInfoStore = useUserInfoStore()

      if (userInfoStore.userId === undefined) {
        EventBus.$emit(authEvents.OPEN_LOGIN_DIALOG);
        return;
      }

      const meta = {
        interval: getInterval(productId),
        tier: getTier(productId),
      }

      // If a user has no subscription yet, create one through paddle.
      if (userInfoStore.tier === 0) {

        let data = { product: productId, ...meta }

        // If the checkout happens during Black Friday and the product is a year plan, add the Black Friday coupon
        const saleStore = useSaleStore()
        if (saleStore.isBlackFriday && [794144, 777416].includes(productId)) {
          data = { ...data, coupon: 'BLACKFRIDAY22' };
        }

        if (discountCode) {
          data = { ...data, coupon: discountCode };
        }

        await this.createSubscription(data, reason);
        return;
      }

      // Below is the flow for upgrading a subscription (Silver to Gold).

      const res = await getApiSubscriptionBillingInfo()
        .catch((error) => {
          console.error('Error getting subscription billing info', JSON.stringify(error));
          Sentry.captureException(new Error('Error getting subscription billing info'));
        });

      if (!res.isSuccess) {
        console.error('Error getting subscription billing info', JSON.stringify(res.error));
        throwUpgradeError(res.error, "We're trying to upgrade your subscription but were unable to complete the process. Please try again or contact support if the issue persists")
      }

      // Handle PayPal subscriptions
      if (res.content.subscriptionType === 'paypal') {

        // Screw you Orval >:(
        const paymentLinkResponse = await accountsAxios(`/api/Subscription/GeneratePaymentLinkV2?productId=${productId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        });

        const paymentLinkResult = paymentLinkResponse?.data;

        if (paymentLinkResponse.status !== 200 || !paymentLinkResult.isSuccess) {
          console.log('Error getting payment link', JSON.stringify(paymentLinkResponse.data));
          throwUpgradeError(paymentLinkResponse.data?.error, 'Error upgrading account. Please try again or contact support if the issue persists');
        } else {
          const link = paymentLinkResult.content.value;
          await this.createSubscription({ override: link }, reason)
        }

        return;
      }

      // Handle legacy SL Trial Account subscriptions
      if (res.content.subscriptionPlanId === 'sl_trial_account') {
        const data = { product: productId, ...meta }
        await this.createSubscription(data, reason)
        return;
      }

      const { data: { session } } = await getSession();

      // Handle Paddle subscriptions
      // Screw you Orval >:(
      const changePlanResponse = await accountsAxios(`/api/Subscription/ChangePlan?newPlanId=${productId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
      })

      if (changePlanResponse.status !== 200 || !changePlanResponse.data?.isSuccess) {
        console.log('Error changing plan', JSON.stringify(changePlanResponse.data));
        throwUpgradeError(changePlanResponse.data.error, 'Error upgrading account. Please try again or contact support if the issue persists')
      } else {
        EventBus.$emit(upgradeEvents.UPGRADE_SUCCESS);
      }

      await userInfoStore.updateUserInfo();

      const throwUpgradeError = (error, fallbackMessage) => {
        console.log(JSON.stringify(error));
        console.error(`Error while upgrading account subscription`, JSON.stringify(error));
        Sentry.captureException(new Error('Error upgrading account'));

        const message = error.errorCode === 502
            ? error.errorMessage ?? fallbackMessage
            : fallbackMessage;

        throw new Error(message);
      };
    },
    async createSubscription(data, reasonOpened) {
      const { createSubscription } = useCreateSubscription()
      await createSubscription(data, reasonOpened)
    },
  },
}