<script setup lang="ts">
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed } from 'vue'
import { tiers } from '@/enums/tiers'
import ArrowRightIcon from '@/components/Icons/ArrowRightIcon.vue'
import { useRouter } from 'vue-router'

const userInfoStore = useUserInfoStore()
const isGold = computed(() => userInfoStore.tier === tiers.GOLD)

const router = useRouter()
async function redirectHome() {
  await router.push('/')
}
</script>

<template>
  <div
    class="mx-auto flex max-w-[28rem] flex-col gap-4 rounded-lg border border-indigo-400 bg-indigo-100/20 p-6 2xl:mt-24"
    v-if="isGold"
  >
    <GoldPlanButton class="pointer-events-none mx-auto" />
    <p class="text-center font-light leading-6 text-pretty">
      Start editing your next video now — we'll handle the rendering in the background.
    </p>
    <div
      class="group-hover:shadow-gradient relative h-12 rounded-full shadow-xl transition-all before:transition-all xl:h-10 2xl:h-12"
    >
      <div class="bg-gradient absolute w-full rounded-full" @click="redirectHome">
        <button
          class="btn-lg btn w-full flex-nowrap gap-2 whitespace-nowrap bg-black text-lg font-bold text-white mix-blend-lighten xl:h-10 xl:min-h-[2.5rem] xl:text-sm 2xl:h-12 2xl:min-h-[3rem] 2xl:text-lg"
        >
          Convert another clip <ArrowRightIcon />
        </button>
      </div>
    </div>
  </div>

  <div class="mx-auto flex max-w-[28rem] flex-col gap-4 rounded-lg border border-indigo-400 bg-indigo-100 p-6" v-else>
    <GoldPlanButton class="pointer-events-none mx-auto" />
    <p class="text-center text-sm font-light leading-6 text-balance">
      Render multiple clips in the background and keep editing with our Gold plan.
    </p>
    <div
      class="group-hover:shadow-gradient relative h-12 rounded-full shadow-xl transition-all before:transition-all xl:h-10 2xl:h-12"
    >
      <a href="/upgrade" target="_blank" class="bg-gradient absolute block w-full rounded-full">
        <button
          class="btn-lg btn w-full flex-nowrap gap-2 whitespace-nowrap bg-black text-lg font-bold uppercase text-white mix-blend-lighten xl:h-10 xl:min-h-[2.5rem] xl:text-sm 2xl:h-12 2xl:min-h-[3rem] 2xl:text-lg"
        >
          Upgrade now <ArrowRightIcon />
        </button>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
