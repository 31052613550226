<script setup lang="ts">
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import StickerElement from '@/components/Editor/Movable/StickerElement.vue'
import { computed, watch, onMounted, ref } from 'vue'
import { downloadStickerScreenshot } from '@/lib/downloadStickerImage'
import * as Sentry from '@sentry/vue'
import type TextSticker from '@/components/Stickers/TextSticker.vue'
import { clsx } from 'clsx'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'

const props = defineProps<{ id: string, class?: string }>()

const stickersStore = useStickersStore()
const sticker = stickersStore.selectById(props.id)

let controller = new AbortController()
async function updateStickerUrl() {

  if (sticker.type === 'custom' || sticker.type === 'giphy') return

  controller.abort()
  controller = new AbortController()
  sticker.imageUrl = ''

  try {
    const quality = 360 * Math.min(window.devicePixelRatio, 2)
    const arrayBuffer = await downloadStickerScreenshot(sticker, sticker.scale ?? 1, quality, controller.signal)
    sticker.imageUrl = URL.createObjectURL(new Blob([arrayBuffer]))
  } catch (e) {
    Sentry.captureException(e)
    sticker.imageUrl = ''
  }
}

const { width: movableWidth, height: movableHeight } = useMovableContext()!

onMounted(() => updateStickerUrl())

const hide = computed(() => {
  return sticker.imageUrl !== '' && !sticker.editing
})

watch(() => sticker.editing, () => {

  if (sticker.type === 'custom' || sticker.type === 'giphy') {
    return
  }

  if (sticker.editing) {
    sticker.imageUrl = ''
  } else {
    updateStickerUrl()
  }
})
function handleUpdateContent() {
  updateStickerUrl()
}

const componentRef = ref<InstanceType<TextSticker>>()
function setEditMode() {
  componentRef.value?.setEditMode?.()
}

watch(() => sticker.editing, () => {
  if (sticker.editing) {
    setEditMode()
  }
})
</script>

<template>
  <StickerElement ref="componentRef" v-model="sticker"
    :render-width="movableWidth" :render-height="movableHeight"
    :class="clsx({ 'invisible': hide }, props.class)"
    @updateContent="handleUpdateContent"
  />
</template>

<style scoped lang="scss">

</style>
