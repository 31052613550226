<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo'
import ClipForm from '@/areas/dashboard/components/ClipForm.vue'
import { useTimeBasedGreetings } from '@/areas/dashboard/hooks/useTimeBasedGreetings'
import { useHead } from '@unhead/vue'
import DashboardTwitchClipsGrid from '@/areas/dashboard/components/DashboardTwitchClipsGrid.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useRouter } from 'vue-router'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useStartup } from '@/areas/editor/startup'
import type { Clip } from '@/Hooks/useTwitchClips'

const greeting = useTimeBasedGreetings()
const userInfo = useUserInfoStore()

useHead({
  title: 'Clip Editor',
  meta: [
    {
      name: 'description',
      content: 'Convert your clips to viral TikToks, Instagram Reels and YouTube Shorts',
    },
  ],
})

const resetEditor = useResetEditor()
const { startupFromTwitchClipByClipId } = useStartup()
async function onClipSelected(clip: Clip) {
  resetEditor()
  await startupFromTwitchClipByClipId(clip.id)
}
</script>

<template>
  <main>
    <header
      class="flex w-full flex-col items-center gap-8 rounded-t-xl bg-gradient-to-br from-purple-800 to-indigo-500 dark:from-purple-200 dark:to-indigo-400  p-4 sm:p-8 lg:p-24"
    >
      <div class="flex flex-col items-center gap-1">
        <h1 v-if="userInfo.isAuthenticated" class="text-center text-white text-3xl lg:text-5xl">
          {{ greeting }},
          <span class="font-bold">{{ userInfo.userName }}!</span>
        </h1>
        <h1 v-else class="text-center text-white text-3xl  lg:text-5xl">
          {{ greeting }}!
        </h1>
        <p class="font-light text-white ">Start by selecting a clip</p>
      </div>
      <ClipForm class="mx-auto w-full max-w-[720px] self-stretch" />
    </header>

    <section class="flex flex-col gap-4 px-6 py-8 lg:p-12">
      <DashboardTwitchClipsGrid @selected="onClipSelected" />
    </section>
  </main>
</template>

<style lang="scss" scoped></style>
