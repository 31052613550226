<script setup lang="ts">
import UpgradeDialog from './components/Dialog/Gold/UpgradeDialog.vue'
import TwErrorDialog from './components/Dialog/TwErrorDialog.vue'
import DialogWrapper from '@/components/Dialog/Confirm/DialogWrapper.vue'
import HasUpgradedDialog from '@/components/Dialog/Gold/HasUpgradedDialog.vue'
import Toast from '@/components/Toast/Toast.vue'
import { useHead } from '@unhead/vue'
import { useDebug } from '@/components/Debug/useDebug'
import DebugMenu from '@/components/Debug/DebugMenu.vue'
import EventBusLoginDialog from '@/components/Dialog/Auth/EventBusLoginDialog.vue'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { useContentPublisherToasts } from '@/components-v2/content-publisher/toasts/useContentPublisherToasts'
import UserEndpointLoader from '@/components/UserEndpointLoader.vue'

useHead({
  title: 'App',
  titleTemplate: '%s | StreamLadder',
})

const debug = useDebug()

useContentPublisherToasts();
</script>

<template>
  <div class="relative z-0">
    <UserEndpointLoader />
    <router-view />
    <has-upgraded-dialog />
    <upgrade-dialog />
    <EventBusLoginDialog />
    <TwErrorDialog />
    <DialogWrapper />
    <DebugMenu v-if="debug" />
    <VueQueryDevtools v-if="false" />
    <Toast />
  </div>
</template>

<script></script>
