<template>
  <Button v-if="datetime" variant="outline" size="lg" class="w-full md:w-auto" @click="datetime = undefined">
    <span class="hidden md:inline">{{ longLocaleDate }}</span>
    <span class="md:hidden">{{ shortLocaleDate }}</span>
    <IconSaxCloseCircle class="h-5" />
    <DynamicPlanButton :small="true" feature="scheduler" />
  </Button>
  <Button v-else class="w-full md:w-auto" size="lg" variant="outline" @click="openTimePickerModal">
    <span>Schedule</span>
    <IconSaxCalendar2 class="h-5" />
    <DynamicPlanButton :small="true" feature="scheduler" />
  </Button>
  <span v-for="error of errors?.['dateTime']" :key="error.code" class="text-error">
    {{ error.message }}
  </span>
  <DateTimeDialog v-model:date="datetime" v-model:open="isDateTimeDialogOpen" />
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import DynamicPlanButton from '@/components/Account/Upgrade/DynamicPlanButton.vue'
import IconSaxCalendar2 from '@/components/Icons/iconsax/IconSaxCalendar2.vue'
import { Button } from '@/components/ui/button'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { DateTimeDialog } from '@/components/date-time-dialog'

const props = defineProps<{
  errors?: Record<string, { code: string; message: string }[]>
}>()

const isDateTimeDialogOpen = ref(false)

const datetime = defineModel<Date | undefined>('datetime')

const contentPublisherStore = useContentPublisherStore()
function openTimePickerModal() {
  if (!canGuardWithPopup('scheduler')) return
  contentPublisherStore.refetch()
  isDateTimeDialogOpen.value = true
}

const shortLocaleDateFormatter = new Intl.DateTimeFormat(document.documentElement.lang, {
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
})

const shortLocaleDate = computed(() => {
  if (datetime.value) {
    return shortLocaleDateFormatter.format(datetime.value)
  } else {
    return undefined
  }
})

const longLocaleDateFormatter = new Intl.DateTimeFormat(document.documentElement.lang, {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
})

const longLocaleDate = computed(() => {
  if (datetime.value) {
    return longLocaleDateFormatter.format(datetime.value)
  } else {
    return undefined
  }
})
</script>
