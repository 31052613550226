<script setup lang="ts">
import type { Tier } from '@/data/plans'
import { useUserInfoStore } from '@/store/user/userInfo'
import TierPrice from '@/components/Tiers/TierPrice.vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import { computed } from 'vue'

const props = defineProps<{ tier: Tier; compact?: boolean; reason: string }>()

const userInfoStore = useUserInfoStore()
const { interval } = useSubscriptionIntervals()
const { isLoading } = usePlanUpgrade()

const highlighted = computed(() => props.tier.key === 'silver');

const features = computed(() => {
  if (props.tier.key === 'silver') {
    return props.tier.features.slice(0, 3);
  } else {
    return props.tier.features.slice(0, 5);
  }
});
</script>

<template>
  <div class="flex flex-col justify-center items-center h-auto hover:scale-[101%] transition-transform" :class="{ 'py-4': highlighted }">
    <article
      :class="{
        'bg-gradient shadow-blue-300/30 hover:shadow-blue-300/50': highlighted,
        'border-gray-300 bg-white/95': !highlighted,
        'cursor-default': tier.tier <= userInfoStore.tier,
        'cursor-pointer hover:shadow-xl': tier.tier > userInfoStore.tier,
      }"
      class="group relative flex h-full flex-col rounded-xl text-left shadow-xl backdrop-blur transition-all p-0.5"
    >
    <span
      v-if="highlighted"
      class="flex items-center justify-center py-1 -mt-0.5 w-full text-sm font-light text-white"
    >
      Most Popular 🌟
    </span>

      <div class="flex flex-col gap-6 bg-white p-8 rounded-xl rounded-t" :class="{ 'px-5': !highlighted, 'rounded-b-xl': highlighted }">

        <header class="flex flex-col font-bold text-center">
          <h3 class="p-1 text-3xl">
            {{ tier.title }}
          </h3>
          <p class="font-light text-xs text-gray-500">
            {{ tier.key === 'silver' ? 'Create clips that get views' : 'Create and schedule your clips for the most views' }}
          </p>
        </header>

        <TierPrice
          :class="{
          'group-hover:text-[#78767B]': tier.tier > userInfoStore.tier,
        }"
          :interval="interval"
          :tier="tier.key"
          class="transition-colors"
        />

        <hr class="my-0.5" />

        <div class="mt-auto">
          <ol class="flex flex-col gap-1.5 text-sm font-thin text-[#78767B]">
            <li v-for="feature in features" v-bind:key="feature" class="flex gap-2">
              <svg
                class="min-w-6 xl:min-w-4 2xl:min-w-6 -mt-0.5 w-6 flex-shrink-0 flex-grow-0 text-[#6080FE] xl:w-4 2xl:w-6"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M20.9557 5.90309L9.05079 19.5087L3.04214 13.5001L4.50055 12.0417L8.95032 16.4915L19.4035 4.54492L20.9557 5.90309Z"
                  fill="currentColor"
                  fill-rule="evenodd"
                />
              </svg>

              {{ feature }}
            </li>
          </ol>
        </div>
        <div
          v-if="isLoading === tier.key"
          :class="!highlighted ? 'btn-outline border-2 bg-white' : ''"
          class="btn-disabled loading btn-lg btn whitespace-nowrap font-bold xl:h-10 xl:min-h-[2.5rem] 2xl:h-12 2xl:min-h-[3rem]"
        >
          Upgrading to {{ tier.title }}...
        </div>
        <div
          v-else-if="tier.tier > userInfoStore.tier && !isLoading"
          :class="{ 'shadow-xl': highlighted }"
          class="group-hover:shadow-gradient relative h-12 rounded-full transition-all before:transition-all xl:h-10 2xl:h-12"
        >
          <div class="bg-gradient absolute w-full rounded-full">
            <button
              :class="
                highlighted
                  ? 'bg-black text-white'
                  : 'btn-outline border-2 bg-white text-black group-hover:border-black group-hover:bg-black group-hover:text-white'
              "
              class="btn-lg btn w-full flex-nowrap gap-2 whitespace-nowrap font-bold mix-blend-lighten xl:h-10 xl:min-h-[2.5rem] xl:text-sm 2xl:h-12 2xl:min-h-[3rem] 2xl:text-lg"
            >
              Choose plan
            </button>
          </div>
        </div>
        <div v-else-if="tier.tier === userInfoStore.tier" class="btn-outline btn-disabled btn-lg btn border-2 bg-white">
          Current plan
        </div>
        <a
          @click.stop
          class="link-hover link text-center text-sm font-bold text-[#2F184E]"
          :class="isLoading && 'pointer-events-none'"
          href="/upgrade#compare"
          target="_blank"
        >
          View all {{ tier.title }} features
        </a>
      </div>
    </article>
  </div>
</template>
