<script setup lang="ts">
import { shapes, type Shape } from '@/modules/CustomLayouts/@data/shapes'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'
import { PopoverTrigger, Popover, PopoverContent } from '@/components/ui/popover'
import { computed, ref } from 'vue'
import ShapeIcon from '@/modules/CustomLayouts/Crop/CropToolbar/ShapeIcon.vue'
import { resizeAspectLockedFromCenter } from '@/modules/SLMovable/helpers/resize/resizeAspectLockedFromCenter'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { Size } from '@/modules/SLMovable/@types/Movable'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import { Button } from '@/components/ui/button'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { calculateRemainingAspectRatio } from '@/areas/editor/@data/prefabs/_helpers'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { ToolbarSeparator } from 'radix-vue'

const props = defineProps<{ id: string }>()
const cropsStore = useCropsStore()
const crop = cropsStore.selectById(props.id)
const shapeModel = cropsStore.selectShapeById(props.id)
const layoutsStore = useLayoutsStore()
const layout = computed(() => layoutsStore.selectById(crop.layoutId))

const videoStore = useVideoStore()

function resizeSplit(aspectLock: Size, video: Size, feed: Size) {

  const isTopCrop = (layout.value.presetId === 'split' && crop.input.name === 'Facecam')
    || (layout.value.presetId === 'basecam' && crop.input.name === 'Gameplay')
  const otherCrop = cropsStore.entities.find((c) => c.layoutId === crop.layoutId && c.id !== props.id)!

  const cropArea = {
    x: crop.x * video.width,
    y: crop.y * video.height,
    width: crop.width * video.width,
    height: crop.height * video.height,
  }

  const cropResize = resizeAspectLockedFromCenter(
    cropArea,
    { x: cropArea.x + cropArea.width, y: cropArea.y + cropArea.height },
    ['s', 'e'],
    { top: 0, left: 0, right: video.width, bottom: video.height },
    aspectLock,
    null)

  cropsStore.updateCropAreaById(props.id, {
    x: cropResize.x / video.width,
    y: cropResize.y / video.height,
    width: cropResize.width / video.width,
    height: cropResize.height / video.height,
  })
  
  const newWidth = feed.width
  const newHeight = feed.width / cropResize.width * cropResize.height
  const newX = 0
  const newY = isTopCrop ? 0 : feed.height - newHeight

  cropsStore.updateCropFeedDataById(props.id, {
    x: newX / feed.width,
    y: newY / feed.height,
    width: newWidth / feed.width,
    height: newHeight / feed.height,
  })

  const otherCropAspectRatio = calculateRemainingAspectRatio(newWidth, newHeight)
  const otherCropWidth = otherCrop.feedData.width * feed.width
  const otherCropHeight = otherCropWidth / otherCropAspectRatio

  const otherNewWidth = feed.width
  const otherNewHeight = feed.width / otherCropWidth * otherCropHeight
  const otherNewX = 0
  const otherNewY = isTopCrop ? feed.height - otherNewHeight : 0

  cropsStore.updateCropFeedDataById(otherCrop.id, {
    x: otherNewX / feed.width,
    y: otherNewY / feed.height,
    width: otherNewWidth / feed.width,
    height: otherNewHeight / feed.height,
  })

  const otherCropArea = {
    x: otherCrop.x * video.width,
    y: otherCrop.y * video.height,
    width: otherCrop.width * video.width,
    height: otherCrop.height * video.height,
  }

  const otherCropResize = resizeAspectLockedFromCenter(
    otherCropArea,
    { x: otherCropArea.x + otherCropArea.width, y: otherCropArea.y + otherCropArea.height },
    ['s', 'e'],
    { top: 0, left: 0, right: video.width, bottom: video.height },
    { width: otherCropWidth, height: otherCropHeight },
    null)

  cropsStore.updateCropAreaById(otherCrop.id, {
    x: otherCropResize.x / video.width,
    y: otherCropResize.y / video.height,
    width: otherCropResize.width / video.width,
    height: otherCropResize.height / video.height,
  })

  cropsStore.state[otherCrop.id].input.shape = 'freeform'
}

function updateShape(shape: Shape) {

  const { aspectLock } = shapes.find((s) => s.value === shape)!
  if (!aspectLock) return

  const { videoSize } = videoStore
  if (!videoSize) return
  
  const feedSize = { width: 1080, height: 1920 }

  if (layout.value.presetId === 'split' || layout.value.presetId === 'basecam') {
    resizeSplit(aspectLock, videoSize, feedSize)
    return
  }

  const cropArea = {
    x: crop.x * videoSize.width,
    y: crop.y * videoSize.height,
    width: crop.width * videoSize.width,
    height: crop.height * videoSize.height,
  }

  const cropResize = resizeAspectLockedFromCenter(
    cropArea,
    { x: cropArea.x + cropArea.width, y: cropArea.y + cropArea.height },
    ['s', 'e'],
    { top: 0, left: 0, right: videoSize.width, bottom: videoSize.height },
    aspectLock,
    null)

  cropsStore.updateCropAreaById(props.id, {
    x: cropResize.x / videoSize.width,
    y: cropResize.y / videoSize.height,
    width: cropResize.width / videoSize.width,
    height: cropResize.height / videoSize.height,
  })
  
  const feedData = {
    x: crop.feedData.x * feedSize.width,
    y: crop.feedData.y * feedSize.height,
    width: crop.feedData.width * feedSize.width,
    height: crop.feedData.height * feedSize.height,
  }

  const feedResize = resizeAspectLockedFromCenter(
    feedData,
    { x: feedData.x + feedData.width, y: feedData.y + feedData.height },
    ['s', 'e'],
    { top: 0, left: 0, right: feedSize.width, bottom: feedSize.height },
    aspectLock,
    null)

  cropsStore.updateCropFeedDataById(props.id, {
    x: feedResize.x / feedSize.width,
    y: feedResize.y / feedSize.height,
    width: feedResize.width / feedSize.width,
    height: feedResize.height / feedSize.height,
  })

  useHistoryStore().push()

  // Model binding fails if parent element is immediately dismounted
  setTimeout(() => {
    isOpen.value = false
  }, 0)
}

const selected = computed(() => {
  const shape = shapes.find((s) => s.value === shapeModel.value);
  if (shape) {
    return shape
  } else {
    return shapes[0]
  }
})

const isOpen = ref(false)

const allowedShapes = computed(() => {
  if (layout.value.presetId === 'split' || layout.value.presetId === 'basecam') {
    return shapes.filter((s) => s.value !== '9:16')
  } else {
    return shapes
  }
})
</script>

<template>
  <Popover v-model:open="isOpen">
    <PopoverTrigger>
      <Button variant="ghost" size="sm" class="font-medium rounded-md text-left text-sm">
        <ShapeIcon :shape="selected.value" />
        <span class="max-w-[130px] md:max-w-full overflow-hidden truncate">{{ selected.label }}</span>

        <IconSaxArrowDown2
          class="ml-auto h-4 w-4 shrink-0 transition-transform"
          :class="isOpen ? 'rotate-180' : ''"
        />
      </Button>
    </PopoverTrigger>
    <PopoverContent
      :side-offset="8"
      class="layer-2 flex flex-col gap-1 rounded-md p-1 shadow-lg data-[state=open]:animate-[slideUpAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards]"
      side="bottom"
      @click.stop
    >
      <Button
        :key="shape.value"
        v-for="shape in allowedShapes"
        as="label"
        class="cursor-pointer font-medium rounded-md text-sm"
        variant="ghost"
        size="sm"
      >
        <input
          v-model="shapeModel"
          :value="shape.value"
          class="absolute opacity-0"
          type="radio"
          @click="updateShape(shape.value)"
        />
        <ShapeIcon :shape="shape.value" />
        {{ shape.label }}
        <IconSaxTickCircle
          :class="shape.value === selected.value ? 'visible' : 'invisible'"
          class="ml-auto h-4 w-4 shrink-0 text-sky-400"
        />
      </Button>
    </PopoverContent>
  </Popover>
  <ToolbarSeparator class="h-6 w-px bg-zinc-200" />
</template>

<style scoped lang="scss"></style>
