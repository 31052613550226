<script setup lang="ts">
import { Button } from '@/components/ui/button'
import type { ClipGptClipDto } from '@/apis/streamladder-api/model'
import { useClipboard } from '@vueuse/core'
import IconSaxDocumentDownload from '@/components/Icons/iconsax/IconSaxDocumentDownload.vue'
import IconSaxEdit from '@/components/Icons/iconsax/IconSaxEdit.vue'
import { noop } from 'lodash-es'
import { posthog } from 'posthog-js'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { jsonToRouteQuery, ensureValidSnapshot } from '@/areas/editor/store/useHistoryStore'
import type { Project } from '@/areas/editor/@type/Project'

const props = defineProps<{ project: Project, clip: ClipGptClipDto, clips: ClipGptClipDto[] }>()
const emit = defineEmits<{
  (event: 'play', url: string): void
}>()

const { copy, copied, isSupported } = useClipboard()

const router = useRouter()
const url = computed(() => {

  const project: Project = ensureValidSnapshot({
    id: props.clip.id!,
    title: props.clip.title ?? 'StreamLadder AI Clip',
    mp4Url: props.clip.videoUrl!,
    source: 'twitch-vod',
    language: props.project.language,
  })

  return router.resolve({ name: editorRouteNames.root, query: { s: jsonToRouteQuery(project) } }).href
})

async function trackClipEdit() {
  const index = props.clips.findIndex(c => c.id === props.clip.id)
  posthog.capture('ClipGPT Clip Edit', {
    item: (index + 1) + '/' + props.clips.length,
    score: props.clip.score,
  })
}

function onClick(event: MouseEvent) {
  if (event.target instanceof HTMLVideoElement) {
    event.preventDefault()
    if (event.target.paused) {
      event.target.play()
    } else {
      event.target.pause()
    }
  }
}
</script>

<template>
  <article class="layer-2 bg-surface-panel-bg border-surface-panel-border rounded-2xl p-4 lg:p-8 grid grid-cols-1 lg:grid-cols-5 gap-6 shadow transition-colors">
    <div class="flex flex-col gap-2 lg:col-span-3">
      <video
        :poster="clip.videoUrl + '.webp'"
        preload="none"
        :src="clip.videoUrl!" ref="videos" @play="emit('play', clip.videoUrl!)"
        @click="onClick"
        class="rounded-lg cursor-pointer"
        disablePictureInPicture disableRemotePlayback
        loop playsinline controls
      />
    </div>

    <div class="flex flex-col gap-2 justify-between lg:col-span-2">
      <header class="flex flex-col gap-4">
        <span class="inline-flex font-medium px-3 py-1 text-green-800 bg-green-200 self-start rounded-full whitespace-nowrap" v-if="clip.score! > 75">
          🔥 {{ clip.score }}/100
        </span>
        <span class="inline-flex font-medium px-3 py-1 text-orange-800 bg-orange-200 self-start rounded-full whitespace-nowrap" v-else-if="clip.score! > 70">
          🚀️ {{ clip.score }}/100
        </span>
        <span class="inline-flex font-medium px-3 py-1 text-white text-yellow-800 bg-yellow-200 self-start rounded-full whitespace-nowrap" v-else>
          🗣 {{ clip.score }}/100
        </span>
        <div class="flex items-center gap-2">
          <h3 class="text-xl md:text-2xl lg:text-3xl font-semibold text-pretty">
            {{ clip.title }}
          </h3>
        </div>
        <p class="p-2 rounded-xl border border-border bg-background flex flex-col gap-2"  @click="() => isSupported ? copy(`${clip.hook} ${clip.hashtags?.join(' ')} #StreamLadderAI`) : noop">
          <span class="font-light p-2 select-all">
            {{ clip.hook }} {{ clip.hashtags?.join(' ') }} #StreamLadderAI
          </span>
          <Button class="self-end" size="sm" variant="ghost" v-if="isSupported">
            <template v-if="copied">✓ Copied</template>
            <template v-else>Copy</template>
          </Button>
        </p>
      </header>

      <footer class="flex flex-wrap gap-2">
        <Button as-child @click="trackClipEdit" class="w-auto flex-1" size="lg">
          <a :href="url" target="_blank">
            Edit as vertical clip <IconSaxEdit class="w-4 h-4" />
          </a>
        </Button>
        <Button as-child class="w-auto flex-1" variant="depressed" size="lg">
          <a :href="clip.videoUrl!" download target="_self">
            Download clip <IconSaxDocumentDownload class="w-4 h-4" />
          </a>
        </Button>
      </footer>
    </div>
  </article>
</template>

<style scoped lang="scss">

</style>
