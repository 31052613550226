<script setup lang="ts">
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import MovableRoot from '@/modules/SLMovable/MovableRoot.vue'
import { provide, ref, computed, watch } from 'vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useRafFn, useElementSize, useElementBounding } from '@vueuse/core'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { contain } from '@/modules/SLMovable/helpers/fit'
import type { Size } from '@/modules/SLMovable/@types/Movable'
import CanvasPreview from '@/areas/editor/workspaces/preview/CanvasPreview.vue'
import { createWorkspaceBoundingContext } from '@/areas/editor/context/workspaceSize'
import UploadWorkspaceMobile from '@/areas/editor/workspaces/UploadWorkspaceMobile.vue'
import { newCrop, useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import CropElement from '@/areas/editor/workspaces/preview/cropper/CropElement.vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import { Select, SelectContent, SelectItem, SelectTrigger } from '@/components/ui/select'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { Switch } from '@/components/ui/switch'
import { Button } from '@/components/ui/button'
import { useCurrentSegment } from '@/areas/editor/store/useCurrentSegment'
import { useUserInfoStore } from '@/store/user/userInfo'
import WorkspaceSpinner from "@/areas/editor/workspaces/WorkspaceSpinner.vue";
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'

const isBottomPanelOpen = defineModel<boolean>('isBottomPanelOpen', { required: true })
const cropperOrPreviewSwitchMobile = defineModel<'cropper' | 'preview'>('cropperOrPreviewSwitchMobile', { required: true })

const clipInfoStore = useEditorClipInfoStore()

const cropsStore = useCropsStore()
const layoutsStore = useLayoutsStore()
const segment = useCurrentSegment()
const layoutId = computed(() => segment.value?.layoutId)

const layout = computed(() => layoutsStore.selectById(layoutId.value))
const crops = cropsStore.whereLayoutIdIs(layoutId)

const editorFocusStore = useEditorFocusStore()
const videoStore = useVideoStore()
const canvas = ref<HTMLCanvasElement | null>(null)
provide('canvas', canvas)

const cropper = ref<HTMLElement | null>(null)
const { width: cropperWidth, x: cropperX, y: cropperY } = useElementBounding(cropper)
const cropperHeight = computed(() => cropperWidth.value * (9 / 16))

const relativeVideoSize = computed(() => {
  if (videoStore.videoSize) {
    return contain(videoStore.videoSize, { width: cropperWidth.value, height: cropperHeight.value })
  } else {
    return null
  }
})

const canvasSize = computed(() => {
  if (!relativeVideoSize.value || !videoStore.videoSize) {
    return null
  } else {
    return contain(videoStore.videoSize, relativeVideoSize.value!)
  }
})

const width = computed(() => relativeVideoSize.value?.width)
const height = computed(() => relativeVideoSize.value?.height)

function computeCropArea({ width, height }: Size) {

  if (!canvasSize.value) return 0

  const cropSize = { width: width * canvasSize.value.width, height: height * canvasSize.value.height }
  return cropSize.width * cropSize.height
}

const sortedCropIds = computed(() => {
  return [...crops.value]
    .sort((a, b) => computeCropArea(b) - computeCropArea(a))
    .map(crop => crop.id)
})

const container = ref<HTMLElement | null>(null)
const { forceUpdate, left, top, width: workspaceWidth } = createWorkspaceBoundingContext(container)

provide('root', container)
provide('mouseover', ref<string | null>(null))

const step = computed(() => {
  if (clipInfoStore.loadingState) {
    return 'loading'
  } else if (!clipInfoStore.id) {
    return 'upload'
  } else {
    return 'edit'
  }
})

const availableWorkspaceWidth = computed(() => workspaceWidth.value - 25)

const { isLayoutsStep, isZoomStep, currentStep } = useEditorStep()

const absoluteCropperWidth = computed(() => {
  if (isLayoutsStep) {
    return availableWorkspaceWidth.value
  } else {
    return 0
  }
})

const cropperVisible = computed(() => {
  return cropperOrPreviewSwitchMobile.value === 'cropper' && isLayoutsStep.value;
});

const previewVisible = computed(() => {
  if (cropperOrPreviewSwitchMobile.value === 'preview' && isLayoutsStep.value) {
    return true;
  } else if (!isLayoutsStep.value) {
    return true;
  } else {
    return false;
  }
});

watch(isLayoutsStep, () => {
  if (isLayoutsStep.value) {
    cropperOrPreviewSwitchMobile.value = 'cropper'
  }
}, { immediate: true });

watch(cropperOrPreviewSwitchMobile, (cropperOrPreview) => {
  isBottomPanelOpen.value = cropperOrPreview !== 'preview'
}, { immediate: true });

const safeZone = ref<'youtube' | 'tiktok' | 'instagram' | 'none'>('none')
const safeZones = computed(() => [
  { key: 'none', show: safeZone.value === 'none' },
  { key: 'youtube', show: safeZone.value === 'youtube' },
  { key: 'tiktok', show: safeZone.value === 'tiktok' },
  { key: 'instagram', show: safeZone.value === 'instagram' },
])

const enableSnapping = ref(true)

function addCrop() {

  if (!canGuardWithPopup('custom-layouts')) return;

  if (!videoStore.videoSize) throw new Error('Video size is not set')

  const crop = newCrop(layoutId.value, videoStore.videoSize, crops.value.length)
  cropsStore.createById(crop.id, crop)

  const editorFocusStore = useEditorFocusStore()
  editorFocusStore.setFocus('crop', crop.id)
}

const cropArea = computed(() => {
  if (!videoStore.videoSize) {
    return null
  } else {
    const scale = Math.min(cropperWidth.value / videoStore.videoSize.width, cropperHeight.value / videoStore.videoSize.height)
    return {
      width: videoStore.videoSize.width * scale,
      height: videoStore.videoSize.height * scale,
    }
  }
})

const previewHeight = computed(() => availableWorkspaceWidth.value / (9 / 16))

const minCropDensity = computed(() => {
  if (videoStore.videoSize) {
    const feedHeight = previewHeight.value
    const cropHeight = videoStore.videoSize.height
    const densities = cropsStore.entities
      .map(crop => (crop.height * cropHeight) / (crop.feedData.height * feedHeight))
      .filter(density => density && !isNaN(density))
    return Math.min(...densities)
  } else {
    return null
  }
})

watch(minCropDensity, (density) => {
  if (density && videoStore.videoSize) {

    const { width, height } = videoStore.videoSize
    const maxUtilizedHeight = height / density
    const maxUtilizedWidth = width / density

    videoStore.resizeCanvas(
      Math.min(width, maxUtilizedWidth * window.devicePixelRatio),
      Math.min(height, maxUtilizedHeight * window.devicePixelRatio))
  }
})

useRafFn(() => {

  if (canvas.value && cropArea.value && videoStore.canvas) {

    if (!cropArea.value.width || !cropArea.value.height) return

    canvas.value.width = cropArea.value.width * Math.min(2, window.devicePixelRatio)
    canvas.value.height = cropArea.value.height * Math.min(2, window.devicePixelRatio)
    canvas.value.style.width = '100%'
    canvas.value.style.height = '100%'

    const ctx = canvas.value.getContext('2d')
    ctx?.drawImage(videoStore.canvas, 0, 0, canvas.value.width, canvas.value.height)
  }
})

watch([currentStep, previewVisible], () => {
  forceUpdate()
  setTimeout(() => {
    forceUpdate()
  }, 500)
})
</script>

<template>
  <div
    id="workspace"
    ref="container"
    @click="editorFocusStore.unFocus()"
    :key="step"
    class="flex items-center justify-center w-full h-full bg-zinc-200 dark:bg-zinc-100 overflow-hidden"
  >
    <UploadWorkspaceMobile v-if="step === 'upload'" />
    <div
      v-else-if="step === 'loading'"
    >
      <WorkspaceSpinner />
    </div>
    <div v-else-if="step === 'edit'" class="flex flex-col gap-1 justify-center items-center w-full h-full py-1">
      <div v-if="isLayoutsStep" class="flex flex-row gap-2 items-center justify-center w-full text-sm">
        <RadioToggleButton v-model="cropperOrPreviewSwitchMobile" :value="'cropper'" class="h-7 w-24 flex-col relative font-light data-[state=active]:font-semibold shrink-0">
          <span class="absolute inset-auto">Crop</span>
        </RadioToggleButton>
        <RadioToggleButton v-model="cropperOrPreviewSwitchMobile" :value="'preview'" class="h-7 w-24 flex-col relative font-light data-[state=active]:font-semibold shrink-0">
          <span class="absolute inset-auto">Preview</span>
        </RadioToggleButton>
      </div>
      <div v-if="previewVisible && isLayoutsStep" class="preview-grid__preview-options mt-0 relative z-[10]">
        <Select v-model="safeZone">
          <SelectTrigger>
              <span v-if="safeZone === 'none'" class="flex items-center gap-2 text-xs">
                No safe zones
              </span>
            <span v-if="safeZone === 'youtube'" class="flex items-center gap-2 text-xs">
                <YoutubeIcon class="text-[#F00] w-3 h-3" /> YouTube preview
              </span>
            <span v-if="safeZone === 'tiktok'" class="flex items-center gap-2 text-xs">
                <TikTokIcon class="w-3 h-3"/> TikTok preview
              </span>
            <span v-if="safeZone === 'instagram'" class="flex items-center gap-2 text-xs">
                <InstagramIcon class="w-3 h-3"/> Instagram preview
              </span>
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none" class="flex items-center gap-2">
              No safe zones
            </SelectItem>
            <SelectItem value="youtube" class="flex items-center gap-2">
              <YoutubeIcon class="text-[#F00] w-3 h-3" /> YouTube preview
            </SelectItem>
            <SelectItem value="tiktok" class="flex items-center gap-2">
              <TikTokIcon class="w-3 h-3"/> TikTok preview
            </SelectItem>
            <SelectItem value="instagram" class="flex items-center gap-2">
              <InstagramIcon class="w-3 h-3"/> Instagram preview
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div
        v-if="previewVisible"
        class="flex flex-col gap-1 items-center justify-center w-full h-full"
      >
        <div class="flex flex-col items-center max-w-[100vw] w-full h-full" :class="isZoomStep ? 'justify-start' : 'justify-center'">
          <div
            ref="preview"
            id="preview-container"
            class="h-[calc(100%-15px)] max-w-[calc(100%+15px)] aspect-[9/16] box-content p-2 rounded-lg border-zinc-900 bg-black shadow-lg preview-grid__preview z-[10]"
            :class="isZoomStep ? 'h-full max-h-[calc(100vh-545px)]' : ''"
            :style="{ maxHeight: isZoomStep ? null : previewHeight + 'px' }"
          >
            <div class="h-0 pb-[177.78%] relative">
              <CanvasPreview :enable-snapping="enableSnapping" :safe-zones="safeZones" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="cropperVisible"
        class="flex flex-col justify-start items-center w-full"
        :class="{ 'h-full': isBottomPanelOpen }"
      >
        <div
          ref="cropper"
          class="relative preview-grid__cropper box-content grid place-items-center p-2 bg-black rounded-lg"
          :style="{ width: absoluteCropperWidth + 'px' }"
          :class="{ 'overflow-hidden !p-0': !cropperVisible }"
        >
          <div class="aspect-video w-full h-auto grid place-items-center bg-black">
            <canvas ref="canvas" class="rounded-lg overflow-hidden" />
          </div>

          <MovableRoot
            :blackout="sortedCropIds.length > 0"
            class="flex-0 absolute inset-auto"
            mask-class="rounded-lg overflow-hidden"
            :style="{ width: width + 'px', height: height + 'px' }"
            container-selector="#workspace"
            :container-x="left" :container-y="top"
          >
            <template v-if="cropperVisible">
              <CropElement v-for="cropIds in sortedCropIds" :key="cropIds" :id="cropIds" :enable-snapping="enableSnapping" @click.stop />
            </template>
          </MovableRoot>
        </div>

        <div class="preview-grid__cropper-options flex justify-center gap-4 mt-2 relative z-[10] w-full py-2" v-if="cropperVisible">
          <Button v-if="layout && layout.presetId !== 'split' && layout.presetId !== 'basecam'" size="sm" @click="addCrop">
            + Add crop
          </Button>
          <label class="flex items-center gap-1 text-sm">
            <Switch v-model:checked="enableSnapping" />
            Enable snapping
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
