<template>
  <base-dialog ref="baseDialog" :closeable="true" :showDismissButton="true">
    <div v-if="askUpdateExistingTemplate">
      <h2>Update template</h2>
      <div class="text-style-sm pb-2">
        Update this template to easily apply it to your next clip
      </div>
      <div class="text-style-base-bold pb-2 pt-2 text-left">Template name</div>
      <input
        ref="templateInput"
        type="text"
        class="border-grey-200 m-0 block w-full rounded border px-3 py-2 mb-2 text-base text-gray-800 outline-0 transition ease-in-out focus:border-blue-500 focus:outline-none focus:outline-8 focus:ring cursor-not-allowed"
        :value="editorMainStore.layoutName"
        disabled
        :maxlength="10"
      />
      <div class="flex flex-col gap-4 py-2">
        <div class="text-style-base-bold pb-1 text-left">Save template with</div>
        <div class="flex gap-2">
          <Switch disabled id="layout-template" v-model:checked="storedFeatures.layout" />
          <label for="layout-template" class="">Layout</label>
        </div>
        <div class="flex gap-2">
          <Switch id="text-template" v-model:checked="storedFeatures.text" />
          <label for="text-template" class="">Text</label>
        </div>
        <div class="flex gap-2">
          <Switch id="stickers-template" v-model:checked="storedFeatures.stickers" />
          <label for="stickers-template" class="">Stickers</label>
        </div>
<!--        <div class="flex gap-2">-->
<!--          <Switch id="caption-template" v-model:checked="storedFeatures.captions" />-->
<!--          <label for="caption-template" class="">Captions</label>-->
<!--        </div>-->
      </div>
      <div class="flex w-full justify-center gap-x-2 pt-2">
        <Button :disabled="loading" @click="createNew()" variant="outline">
          New
        </Button>
        <Button :disabled="loading" @click="saveTemplate(true)" variant="primary">
          <Spinner v-if="loading" class="h-3 w-3 animate-spin" />
          Update
        </Button>
      </div>
    </div>

    <div v-else-if="showForm">
      <h2>Save template</h2>
      <div class="text-style-sm pb-2">
        Save this template to easily apply it to your next clip
      </div>
      <div class="text-style-base-bold pb-2 pt-2 text-left">Template name</div>
      <form @submit.prevent="submit" class="row flex flex-col gap-4">
        <span>
          <input
            ref="templateInput"
            type="text"
            class="border-grey-200 m-0 block w-full rounded border px-3 py-2 text-base text-gray-800 outline-0 transition ease-in-out focus:border-blue-500 focus:outline-none focus:outline-8 focus:ring"
            v-model="templateName"
            :maxlength="10"
          />
          <p class="validation-message" v-if="validationMessage">
            {{ validationMessage }}
          </p>
        </span>
        <div class="text-style-base-bold pb-1 text-left">Save template with</div>
        <div class="flex gap-2">
          <Switch disabled id="layout-template" v-model:checked="storedFeatures.layout" />
          <label for="layout-template" class="">Layout</label>
        </div>
        <div class="flex gap-2">
          <Switch id="text-template" v-model:checked="storedFeatures.text" />
          <label for="text-template" class="">Text</label>
        </div>
        <div class="flex gap-2">
          <Switch id="stickers-template" v-model:checked="storedFeatures.stickers" />
          <label for="stickers-template" class="">Stickers</label>
        </div>
<!--        <div class="flex gap-2">-->
<!--          <Switch id="caption-template" v-model:checked="storedFeatures.captions" />-->
<!--          <label for="caption-template" class="">Captions</label>-->
<!--        </div>-->
        <div class="flex justify-center gap-x-2 pt-2">
          <Button variant="outline" @click="close" type="button" :disabled="loading">
            Close
          </Button>
          <Button variant="primary" type="submit" :disabled="loading">
            <Spinner v-if="loading" class="h-3 w-3 animate-spin" />
            Save
          </Button>
        </div>
      </form>
    </div>

    <div v-else>
      <div v-if="hasError" class="flex flex-col items-center">
        <error-icon />
        <h2>Something went wrong...</h2>
      </div>
      <div v-else class="flex flex-col items-center gap-2">
        <lottie-animation class="w-28" url="/lottie/success-montage.json" :auto-play="true" :loop="false" />
        <h2>Saved Template</h2>
        <p class="font-light">This window will close automatically in {{ countdown.timer }}...</p>
      </div>
    </div>
  </base-dialog>
</template>

<script setup lang="ts">
import BaseDialog from './BaseDialog.vue'
import templateService from '../../services/templateService'
import ErrorIcon from './Icons/ErrorIcon.vue'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { Button } from '@/components/ui/button'
import { nextTick, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Switch } from '@/components/ui/switch'
import {stickerToStorage} from "@/components/Stickers/stickerStorageMapper";
import { posthog } from 'posthog-js'
import Spinner from '@/components/Icons/Spinner.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import type { Sticker } from '@/apis/streamladder-api/model'

const route = useRoute()

const askUpdateExistingTemplate = ref<boolean>()
const templateName = ref<string>('')
const showForm = ref<boolean>(true)
const loading = ref<boolean>(false)
const hasError = ref<boolean>(false)
const validationMessage = ref<string>('')

const baseDialog = ref<BaseDialog>()

const editorMainStore = useEditorMainStore()

const storedFeatures = reactive({
  text: false,
  layout: true,
  stickers: true,
  captions: true,
})

const countdown = ref({
  interval: 0,
  timer: 4
});

const startAutoClosing = () => {
  countdown.value.interval = setInterval(() => {
    if (countdown.value.timer <= 1) {
      close()
      clearInterval(countdown.value.interval)
    }
    countdown.value.timer -= 1
  }, 1000);
};

const open = () => {

  countdown.value.timer = 4;

  baseDialog.value?.open()
  focusInput()

  askUpdateExistingTemplate.value = !!editorMainStore.templateId
  templateName.value = ''
  showForm.value = true
  hasError.value = false
  loading.value = false
}

const close = () => {
  baseDialog.value?.close()
}

defineExpose({
  open,
  close,
})

const focusInput = () => {
  nextTick(() => {
    if (baseDialog.value?.templateInput) baseDialog.value?.$refs.templateInput.focus()
  })
}

const createNew = () => {
  askUpdateExistingTemplate.value = false
  focusInput()
}

const submit = async () => {
  const templateNameTrimmed = templateName.value.trim()
  if (templateNameTrimmed.length < 4 || templateNameTrimmed.length > 24) {
    validationMessage.value = 'Template name must be between 4 and 24 characters'
    return
  } else {
    await saveTemplate()
  }
}

const saveTemplate = async (updateTemplate = false) => {
  loading.value = true
  const editorMainStore = useEditorMainStore()
  const editorFeedDataStore = useEditorFeedDataStore()
  const croppers = editorFeedDataStore.fragmentArray.map((fragment) => {
    return {
      cropperKey: fragment.key,
      data: fragment.cropData,
    }
  })
  const feeds = editorFeedDataStore.fragmentArray.map((fragment) => {
    return {
      feedKey: fragment.key,
      data: fragment.feedData,
    }
  })

  const stickersStore = useStickersStore()
  const stickers: Sticker[] = stickersStore.selectStickersForStorage()
    .filter((sticker) => {
      if (!storedFeatures.stickers) return sticker.isTextSticker
      return true
    })
    .filter((sticker) => {
      if (!storedFeatures.text) return !sticker.isTextSticker
      return true
    })
  
  const template = {
    id: updateTemplate ? editorMainStore.templateId : null,
    templateName: templateName.value.trim(),
    layoutName: route.params.layout,
    croppers,
    feeds,
    stickers,
  }

  try {
    posthog.capture('Saved Template', {
      template: template,
      hasUpdatedTemplate: updateTemplate,
    });
    const result = await templateService.saveTemplate(template, updateTemplate)
    editorMainStore.templateId = result.id
  } catch (e) {
    hasError.value = true
    console.error(e)
  } finally {
    askUpdateExistingTemplate.value = null
    showForm.value = false
    loading.value = false
    startAutoClosing();
  }
}
</script>

<style scoped lang="scss">
.validation-message {
  color: #f00;
}

.button-group-flex {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.btn-secondary {
  color: #4d287f;
  background: #eaeaea;
}
</style>
