<template>
  <div ref="captionContainer" class="captionContainer" data-retain-focus>
    <div
      v-if="captionsWrapper"
      ref="captionsMoveable"
      class="captionPosition preview-layer relative"
      :class="{
        'active z-[31]': editorFocusStore.focus?.type === FocusTypes.CAPTION,
        'z-30': editorFocusStore.focus?.type !== FocusTypes.CAPTION,
      }"
      :style="{ transform: computedCaptionWrapper }"
    />
  </div>
</template>

<script>
import Moveable from 'moveable'
import { gsap } from 'gsap'
import { TextPlugin } from 'gsap/TextPlugin'
import { EasePack } from 'gsap/EasePack'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { mapState } from 'pinia'
import CaptionElement from '@/components/Captions/CaptionElement.vue'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import { WorkerRenderer } from '@/modules/SLVideoplayer/canvas/WorkerRenderer'
import { useStickersStore } from '@/store/entity-system/useStickersStore'

gsap.registerPlugin(TextPlugin, EasePack)

export default {
  computed: {
    FocusTypes() {
      return FocusTypes
    },
    computedCaptionWrapper() {
      const x = this.captionsWrapper.x * this.containerWidth
      const y = this.captionsWrapper.y * this.containerHeight
      const scale = this.captionsWrapper.scale * this.containerWidth

      return `translate(${x}px, ${y}px) scale(${scale})`
    },
    ...mapState(useEditorFocusStore, ['focus']),
  },
  components: {
    CaptionElement,
  },
  props: {
    captions: {
      type: Array,
      default: () => [],
      required: true,
    },
    captionStyleSettings: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    captionsWrapper: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    containerWidth: {
      type: Number,
      default: 1080,
    },
    containerHeight: {
      type: Number,
      default: 1920,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    enableSnapping: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      moveable: null,
      captionLoadedCounter: 0,
    }
  },
  async mounted() {
    if (this.isEditable) {
      await this.$nextTick()
      this.initMoveable()
      await this.$nextTick()
      this.focusCaption()
    }
  },
  setup() {
    const editorFocusStore = useEditorFocusStore()
    return { editorFocusStore }
  },
  watch: {
    'editorFocusStore.focus'() {
      const element = this.moveable?.getManager()?.controlBox?.getElement()
      if (element) {
        if (this.editorFocusStore.focus?.type !== FocusTypes.CAPTION) {
          element.classList.add('opacity-0', 'pointer-events-none')
        } else {
          element.classList.remove('opacity-0', 'pointer-events-none')
        }
      }
    },
    enableSnapping() {
      if (this.moveable) {
        this.moveable.snappable = this.enableSnapping
      }
    },
    containerWidth() {
      this.moveable.verticalGuidelines = [this.containerWidth / 2]
      this.moveable.bounds = {
        top: -100,
        left: -100,
        bottom: this.containerHeight + 100,
        right: this.containerWidth + 100,
      }

      this.moveable.updateRect()
    },
  },
  methods: {
    captionLoaded(elem) {
      this.captionLoadedCounter++
      if (this.captions.length === this.captionLoadedCounter) {
        this.$emit('captionLoaded')
      }
    },
    initMoveable() {
      // Destroy previous moveable
      if (this.moveable) this.moveable.destroy()

      const target = this.$refs.captionsMoveable
      this.moveable = new Moveable(this.$refs.captionContainer, {
        className: 'moveable-sticker',
        target: target,
        // If the container is null, the position is fixed. (default: parentElement(document.body))
        container: this.$refs.captionContainer,
        ables: [DeleteButton],
        resizable: false,
        scalable: true,
        rotatable: false,
        warpable: false,
        // Enabling pinchable lets you use events that
        // can be used in draggable, resizable, scalable, and rotateable.
        pinchable: false, // ["resizable", "scalable", "rotatable"]
        origin: false,
        keepRatio: true,
        renderDirections: ['w', 'e'],
        // Resize, Scale Events at edges.
        edge: false,
        throttleDrag: 0,
        throttleResize: 0,
        throttleScale: 0,
        throttleRotate: 0,
        snappable: this.enableSnapping,

        props: {
          deleteButton: true,
        },

        snapThreshold: 5,
        snapElement: true,
        snapDirections: { center: true },
        verticalGuidelines: [(this.$refs.captionContainer?.clientWidth ?? 0) / 2],

        draggable: true,
        bounds: {
          top: -100,
          left: -100,
          bottom: (this.$refs.captionContainer?.clientHeight ?? 0) + 100,
          right: (this.$refs.captionContainer?.clientWidth ?? 0) + 100,
        },
      })
      this.moveable.updateTarget()

      this.moveable.on('drag', this.handleDragSticker)
      this.moveable.on('dragEnd', this.onDragEnd)
      this.moveable.on('scale', this.onScale)
      this.moveable.on('scaleEnd', this.onScaleEnd)
      this.moveable.on('dragStart', this.focusCaption)
      this.moveable.on('scaleStart', this.focusCaption)
    },
    handleDragSticker({ target, transform, translate }) {
      target.style.transform = transform

      const x = this.moveable.getRect().left / this.containerWidth
      const y = this.moveable.getRect().top / this.containerHeight
      const editorCaptionsStore = useEditorCaptionsStore()
      const stickersStore = useStickersStore()
      WorkerRenderer.renderer?.worker?.updateOptions(
        // { captionsWrapper: { x, y } },
        editorCaptionsStore, stickersStore);
    },
    focusCaption() {
      this.editorFocusStore.setFocus(FocusTypes.CAPTION)
    },
    onScale(e) {
      const { target, scale, drag } = e

      target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)` + ` scale(${scale[0]})`

      const x = this.moveable.getRect().left / this.containerWidth
      const y = this.moveable.getRect().top / this.containerHeight

      const editorCaptionsStore = useEditorCaptionsStore()
      const stickersStore = useStickersStore()
      WorkerRenderer.renderer?.worker?.updateOptions(
        // { captionsWrapper: { x, y, scale: scale[0] / this.containerWidth } },
        editorCaptionsStore, stickersStore);
    },
    async onDragEnd({ lastEvent }) {
      if (lastEvent) {
        const editorCaptionsStore = useEditorCaptionsStore()

        const x = this.moveable.getRect().left / this.containerWidth
        const y = this.moveable.getRect().top / this.containerHeight
        editorCaptionsStore.captionsWrapper.x = x
        editorCaptionsStore.captionsWrapper.y = y
      }
    },
    async onScaleEnd({ lastEvent }) {
      if (lastEvent) {
        const editorCaptionsStore = useEditorCaptionsStore()

        const x = this.moveable.getRect().left / this.containerWidth
        const y = this.moveable.getRect().top / this.containerHeight
        const scale = lastEvent.scale[0] / this.containerWidth

        editorCaptionsStore.captionsWrapper.x = x
        editorCaptionsStore.captionsWrapper.y = y
        editorCaptionsStore.captionsWrapper.scale = scale
      }
    },
  },
}

const DeleteButton = {
  name: 'deleteButton',
  render(moveable, React) {
    const rect = moveable.getRect()
    return React.createElement(
      'i',
      {
        key: 'moveable-delete-button',
        style: {
          top: `${-10}px`,
          left: `${rect.width / 2 - 10}px`,
        },
        className: 'moveable-delete-button',
        onClick: async () => {
          const captionStore = useEditorCaptionsStore()
          const confirm = useConfirmDialog()
          const confirmed = await confirm.reveal({
            title: 'Delete Captions',
            message: 'Are you sure you want to delete all captions?',
          })
          if (confirmed) captionStore.$reset()
        },
      },
      ''
    )
  },
}
</script>

<style lang="scss" scoped>
.captionContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.captionPosition {
  width: 220px;
  height: 150px;
  display: inline-flex;
  user-select: none;
  line-height: 0;
  transform-origin: top left;
  align-items: center;
  justify-content: center;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.text {
  position: absolute;
}
</style>

<style lang="scss">
.captionContainer {
  font-variant-ligatures: none;

  .moveable-control.moveable-direction {
    margin-top: -10px;
    margin-left: -10px;
  }

  .moveable-line.moveable-direction {
    display: none !important;
  }

  .moveable-sticker {
    z-index: 3001;
  }

  .moveable-sticker .moveable-control.moveable-direction {
    --moveable-color: white;
    width: 20px;
    height: 20px;
  }

  .moveable-control.moveable-direction.moveable-e {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-right: -15px;

    &:before {
      content: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.37769 9.45166L5.28782 5.54152L1.37769 1.63139' stroke='%2347089E' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      margin-left: 2px;
      margin-top: 2px;
    }
  }

  .moveable-control.moveable-direction.moveable-w {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-left: -15px;

    &:before {
      content: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.60864 1.63135L1.69851 5.54148L5.60864 9.45162' stroke='%2347089E' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      margin-left: -2px;
      margin-top: 2px;
    }
  }

  .moveable-delete-button {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.72705 1.5484L1.90693 9.36852' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M9.72705 9.36859L1.90693 1.54847' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-color: #ff4d4d;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    z-index: 11;
    cursor: pointer;
  }
}
</style>
