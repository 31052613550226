<script lang="ts" setup>
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import KickIcon from '@/components/Icons/SocialMedia/KickIcon.vue'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { useClipFormManager } from '@/Hooks/clip-form/useClipFormManager'
import { ref, onMounted, onUnmounted, computed } from 'vue'
import IconSaxGalleryAdd from '@/components/Icons/iconsax/IconSaxGalleryAdd.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import SingleRowClipGrid from '@/components-v2/data-query/SelectClipDialog/SingleRowClipGrid.vue'
import ClipSearchForm from '@/components-v2/data-query/SelectClipDialog/ClipSearchForm.vue'
import IconSaxSearchNormal1 from '@/components/Icons/iconsax/IconSaxSearchNormal1.vue'
import { useResetEditor } from '@/Hooks/useResetEditor'
import type { Clip } from '@/Hooks/useTwitchClips'
import { useRouter, useRoute } from 'vue-router'
import unwrap from '@/helpers/unwrap'
import ClipGoatPromotionModal from '@/pages/modals/ClipGoatPromotionModal.vue'
import IconSaxLoginCurve from '@/components/Icons/iconsax/IconSaxLoginCurve.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import IconSaxHomeTrendUp from '@/components/Icons/iconsax/IconSaxHomeTrendUp.vue'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'
import ClipEditorMultiUploadDialog from '@/components/Dialog/MultiUploadDialog/ClipEditorMultiUploadDialog.vue'
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useStartup } from '@/areas/editor/startup'
import RadixErrorDialog from '@/components/Dialog/RadixErrorDialog.vue'
import { Button } from '@/components/ui/button'
import Spinner from '@/components/Icons/Spinner.vue'

const clipUrl = ref<string>()
const { submitClipUrl, error, youtubeVideoId, downloadError } = useClipFormManager()

const input = ref<HTMLInputElement>()
const search = ref(false)
const userInfoStore = useUserInfoStore()

const reset = useResetEditor()
onMounted(reset)

const { startupFromTwitchClipByClipId } = useStartup()
async function select(clip: Clip) {
  reset()
  await startupFromTwitchClipByClipId(clip.id)
}

const openErrorDialog = computed({
  get: () => downloadError.value !== null,
  set: (value: boolean) => {
    if (!value) {
      downloadError.value = null
    }
  }
});

const openVideoBlockedErrorDialog = computed({
  get: () => !!downloadError.value?.includes('blocked_video'),
  set: (value: boolean) => {
    if (!value) {
      downloadError.value = null
    }
  }
});

const Query = {
  // Clip URL is given by WebFlow.
  CLIP_URL: 'clipUrl',
  // Search clips from Twitch is given by WebFlow
  SEARCH_FROM_TWITCH: 'searchFromTwitch',
}

const route = useRoute()
onMounted(() => {
  const queryKeys = unwrap.keys(route.query) as (typeof Query)[keyof typeof Query][]

  console.log('queryKeys', queryKeys)
  const clipUrlQuery = route.query[Query.CLIP_URL] as string | undefined
  if (clipUrlQuery) {
    submitClipUrl(clipUrlQuery)
  } else if (queryKeys.includes(Query.SEARCH_FROM_TWITCH)) {
    search.value = true
  }

  document.body.style.overflow = 'hidden'
})

onUnmounted(() => {
  document.body.style.overflow = ''
})

const login = ref(false)

const loading = ref(false)

const handleSubmitClipUrl = async (clipUrl: string | undefined) => {
  loading.value = true
  await submitClipUrl(clipUrl)
  loading.value = false
}
</script>

<template>
  <teleport to="body">
    <article class="modal modal-open modal-bottom z-50 md:modal-middle">
      <div
        class="layer-1 modal-box flex !max-w-screen-md flex-col gap-6 !rounded-none p-6 transition-all max-md:h-full md:!rounded-3xl md:p-12"
      >
        <ClipSearchForm v-if="search" v-model="search" @select="select" />
        <template v-else>
          <header class="flex flex-col gap-2">
            <div class="flex flex-wrap-reverse items-end justify-between gap-2">
              <ol class="flex gap-1">
                <li class="flex items-center gap-1.5 rounded bg-[#9147FF] px-2 py-1 font-semibold text-white">
                  <TwitchIcon class="h-5 w-5 shrink-0" />
                  Twitch
                </li>
                <li class="flex items-center gap-1 rounded bg-[#F00] px-2 py-1 font-semibold text-white">
                  <YoutubeIcon class="h-5 w-5 shrink-0" />
                  <p class="translate-y-[1px]">YouTube</p>
                </li>
                <li class="flex items-center gap-2 rounded bg-black px-2 py-1 font-semibold text-white">
                  <KickIcon class="h-4 w-4 shrink-0 text-[#0CFF7F]" />
                  <p class="translate-y-[1px]">Kick</p>
                </li>
              </ol>

              <LoginDialog v-if="!userInfoStore.isAuthenticated">
                <button class="ml-auto flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-indigo-500 px-3 py-1 text-white outline-none transition-colors hover:bg-indigo-600 focus:bg-indigo-600">
                  <IconSaxLoginCurve class="h-4 w-4 shrink-0 [&>path]:stroke-1" />
                  Login
                </button>
              </LoginDialog>
              <RouterLink v-else
                :to="{ name: dashboardRouteNames.dashboard }"
                class="ml-auto flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-indigo-500 px-3 py-1 text-white outline-none transition-colors hover:bg-indigo-600 focus:bg-indigo-600"
              >
                <IconSaxHomeTrendUp class="h-4 w-4 shrink-0 [&>path]:stroke-1" />
                Dashboard
              </RouterLink>
            </div>

            <h4 class="text-light m-0 p-0 text-3xl lg:text-4xl">Start by selecting a clip</h4>

            <p class="font-light opacity-75">
              Enter a Twitch, YouTube or Kick clip, upload your own, or find one on Twitch to begin your editing
              adventure!
            </p>
          </header>

          <section class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <form
                :class="
                  error
                    ? 'border-rose-400 shadow shadow-rose-400/50'
                    : 'border-zinc-900/20 [&:has(input:focus)]:shadow [&:has(input:focus)]:shadow-cyan-400/50'
                "
                class="layer-1 group flex items-stretch rounded-xl transition-all relative"
                @submit.prevent.stop="handleSubmitClipUrl(clipUrl)"
              >
                <div class="shrink-0 pr-0 absolute left-3 top-3 text-rose-500" v-if="error">
                  <IconSaxInfoCircle />
                </div>
                <input
                  ref="input"
                  v-model="clipUrl"
                  :class="error ? 'pl-11 border-rose-400' : 'border-zinc-900/20 focus:border-cyan-400/50'"
                  class="min-w-0 flex-auto rounded-l-xl rounded-r-none border border-r-0 p-3 text-left font-light text-zinc-900 outline-none"
                  placeholder="Paste the URL of a Twitch, YouTube, or Kick clip here"
                  type="text"
                  @input="error = null"
                />
                <button
                  class="group flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-l-none rounded-r-xl bg-purple-800 px-6 py-3 text-white outline-none transition-colors hover:bg-purple-900 focus:bg-purple-900"
                  :class="{ 'pointer-events-none': loading }"
                  type="submit"
                  @click="input?.focus()"
                >
                  <template v-if="loading">
                    <span class="hidden md:inline">Loading..</span>
                    <Spinner class="h-4 w-4 animate-spin mr-2.5" />
                  </template>
                  <template v-else>
                    <span class="hidden md:inline">Start editing</span>
                    <IconSaxArrowRight2
                      class="h-4 w-4 transition-transform group-hover:translate-x-2 group-focus:translate-x-2 md:mr-2"
                    />
                  </template>
                </button>
              </form>

              <ClipUrlError :error="error" />
            </div>

            <div class="flex flex-wrap gap-4">
              <ClipEditorMultiUploadDialog>
                <button
                  class="layer-2 flex flex-auto items-center justify-center gap-2 rounded-xl border border-zinc-900/20 px-6 py-8 outline outline-2 outline-transparent transition-all hover:outline-offset-2 hover:outline-cyan-400 focus:outline-offset-2 focus:outline-cyan-400 active:scale-90 dark:border-zinc-200/20"
                >
                  <IconSaxGalleryAdd class="h-6 w-6 shrink-0 [&>path]:stroke-1" />
                  Upload from computer
                </button>
              </ClipEditorMultiUploadDialog>
              <button
                class="layer-2 flex flex-auto items-center justify-center gap-2 rounded-xl border border-zinc-900/20 px-6 py-8 outline outline-2 outline-transparent transition-all hover:outline-offset-2 hover:outline-cyan-400 focus:outline-offset-2 focus:outline-cyan-400 active:scale-90 dark:border-zinc-200/20"
                @click="search = true"
              >
                <IconSaxSearchNormal1 class="h-6 w-6 shrink-0 [&>path]:stroke-1" />
                Select from Twitch
              </button>
            </div>
          </section>

          <footer class="flex flex-col gap-4">
            <SingleRowClipGrid @select="select" />
          </footer>
        </template>
      </div>
    </article>

    <RadixErrorDialog v-model:open="openErrorDialog">
      <template #title>Something went wrong</template>
      <template #description>
        <div class="flex flex-col gap-4">
          <span>Unfortunately, your clip could not be found. Your clip might still be processing which can take up to 24 hours after creation 🙊. </span>
        </div>
      </template>
      <template #actions>
        <Button variant="primary" @click="openErrorDialog = false">Change clip</Button>
      </template>
    </RadixErrorDialog>

    <RadixErrorDialog v-model:open="openVideoBlockedErrorDialog">
      <template #title>Region blocked 🌎</template>
      <template #description>
        Unfortunately, this clip has been blocked in the USA based on copyright grounds. Please try another clip or use the 'Upload from Twitch' button.
      </template>
      <template #actions>
        <Button variant="primary" @click="openVideoBlockedErrorDialog = false">Change clip</Button>
      </template>
    </RadixErrorDialog>
  </teleport>

  <ClipGoatPromotionModal v-model="youtubeVideoId" />
</template>

<style lang="scss" scoped></style>
