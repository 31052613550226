<script setup lang="ts">
import { useEditorClipInfoStore } from "@/store/editor/editorClipInfo";
import Spinner from "@/components/Icons/Spinner.vue";
import { computed } from 'vue'
import LottieAnimation from "@/components/LottieAnimation.vue";
import { Button } from '@/components/ui/button'
import { editorRouteNames } from '@/areas/editor/routeNames'
import settings from '@/data/settings'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'

const editorClipInfoStore = useEditorClipInfoStore()
const historyStore = useHistoryStore()

const spinnerColor = computed(() => {
  switch (editorClipInfoStore.source) {
    case 'youtube-clip':
      return 'fill-[#FF0000]'
    case 'twitch-clip':
      return 'fill-[#9146FF]'
    default:
      return 'fill-company-primary-200'
  }
})

const title = computed(() => {
  switch (editorClipInfoStore.source) {
    case 'youtube-clip':
      return 'Preparing YouTube Clip'
    case 'twitch-clip':
      return 'Preparing Twitch Clip'
    default:
      return 'Preparing Clip'
  }
})
</script>

<template>
  <template v-if="editorClipInfoStore.loadingState">
    <Transition appear
      enter-active-class="transition-[transform,_opacity] duration-300"
      leave-active-class="transition-[transform,_opacity] duration-300"
      enter-from-class="opacity-0 translate-y-4"
      enter-to-class="opacity-100 translate-y-0"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-4"
    >
      <div class="absolute inset-0 grid place-items-center p-8 w-full h-full" :key="editorClipInfoStore.loadingState.state">
        <div class="flex flex-col gap-1 items-center text-center w-full max-w-[420px]">
          <template v-if="editorClipInfoStore.loadingState.state === 'loading'">
            <Spinner class="w-24 h-24 text-zinc-200 dark:text-black animate-spin mb-6" :class="spinnerColor" />
            <h2 class="text-xl font-semibold leading-snug">
              {{ title }}
            </h2>
            <div class="relative w-full flex justify-center">
              <Transition appear
                enter-active-class="transition-[transform,_opacity] duration-300"
                leave-active-class="transition-[transform,_opacity] duration-300"
                enter-from-class="opacity-0 translate-x-4"
                enter-to-class="opacity-100 translate-x-0"
                leave-from-class="opacity-100 translate-x-0"
                leave-to-class="opacity-0 -translate-x-4"
              >
                <p class="font-light absolute inset-auto text-pretty" :key="editorClipInfoStore.loadingState.description">
                  {{ editorClipInfoStore.loadingState!.description }}
                </p>
              </Transition>
            </div>
          </template>
          <template v-else>
            <LottieAnimation url="/lottie/error.json" class="w-24 h-24 mb-6" :loop="false" />

            <h2 class="text-xl font-semibold leading-snug">
              {{ editorClipInfoStore.loadingState.title ?? 'Something went wrong' }}
            </h2>
            <p class="font-light text-pretty" :key="editorClipInfoStore.loadingState.description">
              {{ editorClipInfoStore.loadingState!.description }}
            </p>
            
            <div class="w-full flex flex-col items-center justify-center mt-6 gap-2">
              <RouterLink :to="{ name: editorRouteNames.root }">
                <Button variant="primary" @click="historyStore.reset()">
                  Try a different video
                </Button>
              </RouterLink>
              <Button as="a" :href="settings.discordInviteUrl" target="_blank" variant="ghost" class="font-normal">
                <DiscordLogo class="shrink-0 w-5 h-5 fill-current" />
                Open a ticket
              </Button>
            </div>
          </template>
        </div>
      </div>
    </Transition>
  </template>
</template>

<style scoped lang="scss">

</style>
