<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import {computed, ref, watch} from 'vue'
import DownloadIcon from '@/components/Icons/DownloadIcon.vue'
import logging from '@/logging'
import { Button } from '@/components/ui/button'
import { useContentPublisherFileData } from '@/areas/dashboard/components/ScheduledPosts/form/useContentPublisherFileData'
import CheckMarkIcon from '@/components/Icons/CheckMarkIcon.vue'
import SenjaTestimonialGrid from '@/components/Senja/SenjaTestimonialGrid.vue'
import { useRouteQuery } from '@vueuse/router'
import { getApiRendersPremiumRenderCount, useGetApiRendersId } from '@/apis/streamladder-api/renders/renders'
import GetQrCode from '@/components/ResultPage/GetQrCode.vue'
import QrIcon from '@/components/Icons/QrIcon.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import {RouterLink, useRouter} from 'vue-router'
import IconSaxSend2 from '@/components/Icons/iconsax/IconSaxSend2.vue'
import EventBus from "@/eventBus";
import mainEvents from "@/events/mainEvents";
import { useUserInfoStore } from '@/store/user/userInfo'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { Progress } from '@/components/ui/progress'

useHead({
  title: 'Video exported',
  meta: [
    {
      name: 'description',
      content: 'Your clip has been successfully exported! You can download or send it to your mobile.',
    },
  ],
})

////////////////////////////////////////////////////////////////////////////
////////////////// Data fetching and reactive properties ///////////////////
////////////////////////////////////////////////////////////////////////////

const { fileData, hasFile } = useContentPublisherFileData()

const renderId = useRouteQuery('renderId')
const { data } = useGetApiRendersId(
  computed(() => renderId.value as string),
  {
    query: {
      enabled: computed(() => renderId.value !== undefined),
    },
  }
)

const router = useRouter()
watch(hasFile, (_hasFile) => {
  if (!_hasFile && !renderId.value) {
    EventBus.$emit(mainEvents.ERROR, 'Something went wrong, we could not find the rendered file. Please try again.')
     router.push({ name: dashboardRouteNames.root })
  }
})

const resultUrl = computed(() => {
  if (data?.value?.value?.resultUrl) {
    return data.value.value.resultUrl
  }
  return fileData?.value.url
})

const clipName = computed(() => {
  return `streamladder-${fileData.value.title}.mp4`
})

function downloadClicked() {
  logging.trackEvent('Clicked Download')
}

////////////////////////////////////////////////////////////////////////////
////////////////// Qr code download modal //////////////////////////////////
////////////////////////////////////////////////////////////////////////////

const qrModal = ref()

function openModal() {
  qrModal.value.publish(resultUrl.value)
  logging.trackEvent('ResultVideo QrCode Generated', {
    Location: 'Dashboard My Clips Page',
    Destination: 'QrCode',
    IsPublic: false,
  })
}

const isFreeUser = useUserInfoStore().tier === 0

const isInPremiumExperiment = useFeatureFlagVariantEnabled('max-5-premium-exports', 'premium');
const premiumRenderCount = ref<number | null>(null);

watch(isInPremiumExperiment, async () => {
  if (isInPremiumExperiment.value) {
    const response = await getApiRendersPremiumRenderCount();
    if (response) {
      premiumRenderCount.value = response.value as number;
    }
  }
}, { immediate: true });

const clampedPremiumRenderCount = computed(() => {
  return premiumRenderCount.value ? Math.min(premiumRenderCount.value, 5) : 0;
});
</script>

<template>
  <main class="flex h-full w-full p-6 lg:p-12">
    <div class="flex w-full flex-col gap-12">
      <div class="flex w-full flex-col items-center gap-8">
        <header class="flex w-full flex-col gap-2 xl:flex-row">
          <div class="mx-auto flex flex-col items-center">
            <h1 class="text-style-4xl">
              Your clip has been <span class="text-green-600">successfully exported!</span>
            </h1>
            <p class="text-style-sm text-brand-state-text-secondary">Ready to download and share!</p>
          </div>
        </header>
        <div class="flex w-full flex-col justify-center gap-8 lg:flex-row">
          <div class="flex flex-col gap-2 lg:w-72">
            <div
              class="mx-auto box-content aspect-[9/16] max-w-sm overflow-hidden rounded-2xl border-[5px] border-surface-inverse bg-surface-inverse xl:mx-0"
            >
              <video ref="resultVideo" :src="resultUrl" class="h-full w-full" controls />
            </div>
            <div class="flex w-full flex-col gap-2">

              <template v-if="isFreeUser">
                <Button
                  variant="outline"
                  size="lg"
                  @click="canGuardWithPopup('publish')"
                >
                  Publish & share
                  <IconSaxSend2 />
                  <DynamicPlanButtonWithTooltip :size="4" small feature="publish" />
                </Button>

                <Button
                  variant="outline"
                  size="lg"
                  class="flex-grow gap-2"
                  @click="canGuardWithPopup('mobile')"
                >
                  Send to Mobile
                  <QrIcon />
                  <DynamicPlanButtonWithTooltip :size="4" small feature="mobile" />
                </Button>

                <Button
                  variant="outline"
                  size="lg"
                  as="a"
                  :download="clipName"
                  :href="resultUrl"
                  id="video-result-page-download-link "
                  @click="downloadClicked"
                  class="flex-grow gap-2"
                >
                  Download
                  <DownloadIcon />
                </Button>
              </template>

              <template v-else>
                <Button
                  v-if="!isFreeUser && renderId"
                  :as="RouterLink"
                  :to="{ name: dashboardRouteNames.contentPublisher.render, params: { renderId: renderId } }"
                  variant="outline"
                  size="lg"
                >
                  Publish & share
                  <IconSaxSend2 />
                </Button>

                <Button v-if="!isFreeUser && renderId" variant="outline" size="lg" class="flex-grow gap-2" @click="openModal">
                  Send to Mobile
                  <QrIcon />
                </Button>

                <Button
                  variant="outline"
                  size="lg"
                  as="a"
                  :download="clipName"
                  :href="resultUrl"
                  id="video-result-page-download-link "
                  @click="downloadClicked"
                  class="flex-grow gap-2"
                >
                  Download
                  <DownloadIcon />
                </Button>
              </template>

              <!--            <Button-->
              <!--              size="lg"-->
              <!--              :as="RouterLink"-->
              <!--              variant="outline"-->
              <!--              @click="logging.trackEvent('Clicked Edit as Montage')"-->
              <!--              :to="{ name: dashboardRouteNames.montageMaker }"-->
              <!--              class="gap-2"-->
              <!--            >-->
              <!--              Edit as Montage-->
              <!--              <IconSaxVideoVertical />-->
              <!--              <DynamicPlanButton :small="true" feature="montage-maker" />-->
              <!--            </Button>-->
            </div>
          </div>
          <div class="flex flex-col gap-4 lg:w-1/2">
            <template v-if="isInPremiumExperiment && clampedPremiumRenderCount < 5">
              <a
                target="_blank"
                href="/upgrade"
                class="group relative h-full cursor-pointer transition-all hover:scale-[1.015] active:scale-95"
              >
                <div
                  class="p-6 md:p-20 w- relative flex h-full flex-col items-center gap-6 overflow-hidden rounded-xl bg-gradient-to-br from-[#FF91ED] to-[#F97D48] shadow-2xl"
                >
                  <button class="transition-transform mb-2 flex justify-center items-center gap-2 w-full overflow-hidden px-4 py-3 rounded-lg shadow-lg bg-surface-panel" @click="openUpgradeDialog">
                    <img
                      src="/images/images/bolt.png"
                      class="h-16 rotate-6"
                      alt="Bolt"
                    />
                    <div class="flex flex-col gap-2 w-full justify-start items-start">
                      <div class="flex justify-between w-full">
                        <span class="text-black dark:text-white font-light">Free Premium Clips created</span>
                        <span class="font-semibold text-lg">{{ clampedPremiumRenderCount }}/5</span>
                      </div>

                      <Progress class="bg-zinc-300" :model-value="clampedPremiumRenderCount * 20" />
                    </div>
                  </button>

                  <div class="ellipse !bottom-1/3 translate-x-2/3" />
                  <div class="ellipse -translate-x-2/3 translate-y-2/3 rotate-[180deg]" />

                  <div class="rounded bg-yellow-50 text-yellow-700 text-lg font-bold px-3 pt-1.5 py-1">
                    <span>Free trial</span>
                  </div>

                  <div>
                    <h3 class="text-style-4xl py-0 text-center leading-[1] !text-white">
                      These features are included
                    </h3>
                  </div>
                  <ul class="text-style-base flex flex-col gap-2 text-white">
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>1080p 60fps Clip Quality</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add and Edit AI-Powered Captions</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add Zoom and Sound effects</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add Texts and Stickers</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add Animated Giphy Stickers</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Use Multiple Layouts in a Single Clip</span>
                    </li>
                  </ul>
                  <div class="flex flex-col gap-4 w-auto">
                    <Button size="lg" variant="dashboardOverlay" class="w-full md:px-10"> Don't want to miss these? Upgrade Now </Button>
                    <Button size="sm" variant="link" class="w-full text-white"> Explore all features </Button>
                  </div>
                </div>
              </a>
            </template>
            <template v-else-if="isInPremiumExperiment && clampedPremiumRenderCount === 5">
              <a
                target="_blank"
                href="/upgrade"
                class="group relative h-full cursor-pointer transition-all hover:scale-[1.015] active:scale-95"
              >
                <div
                  class="p-6 md:p-20 w- relative flex h-full flex-col items-center gap-6 overflow-hidden rounded-xl bg-gradient-to-br from-[#FF91ED] to-[#F97D48] shadow-2xl"
                >
                  <button class="transition-transform mb-2 flex justify-center items-center gap-2 w-full overflow-hidden px-4 py-3 rounded-lg shadow-lg bg-surface-panel" @click="openUpgradeDialog">
                    <img
                      src="/images/images/bolt.png"
                      class="h-16 rotate-6"
                      alt="Bolt"
                    />
                    <div class="flex flex-col gap-2 w-full justify-start items-start">
                      <div class="flex justify-between w-full">
                        <span class="text-black dark:text-white font-light">Free Premium Clips created</span>
                        <span class="font-semibold text-lg">{{ clampedPremiumRenderCount }}/5</span>
                      </div>

                      <Progress class="bg-zinc-300" :model-value="clampedPremiumRenderCount * 20" />
                    </div>
                  </button>

                  <div class="ellipse !bottom-1/3 translate-x-2/3" />
                  <div class="ellipse -translate-x-2/3 translate-y-2/3 rotate-[180deg]" />

                  <div class="rounded bg-yellow-50 text-yellow-700 text-lg font-bold px-3 pt-1.5 py-1">
                    <span>Free trial</span>
                  </div>

                  <div class="flex flex-col gap-1">
                    <h3 class="text-style-4xl max-w-[500px] py-0 text-center leading-[1] !text-white">
                      You've Used All 5 Free Premium Clips!
                    </h3>
                    <p class="max-w-[50ch] text-center font-light leading-[1.5] text-white">
                      You're now set back to the Free Plan with limited features. Upgrade Now to use all the features below:
                    </p>
                  </div>
                  <ul class="text-style-base flex flex-col gap-2 text-white">
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>1080p 60fps Clip Quality</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add and Edit AI-Powered Captions</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add Zoom and Sound effects</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add Texts and Stickers</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add Animated Giphy Stickers</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Use Multiple Layouts in a Single Clip</span>
                    </li>
                  </ul>
                  <div class="flex flex-col gap-4 w-full">
                    <Button size="lg" variant="dashboardOverlay" class="w-full"> Do you miss these? Upgrade Now </Button>
                    <Button size="sm" variant="link" class="w-full text-white"> Explore all features </Button>
                  </div>
                </div>
              </a>
            </template>
            <template v-else>
              <a
                target="_blank"
                href="/upgrade"
                class="group relative h-full cursor-pointer transition-all hover:scale-[1.015] active:scale-95"
              >
                <img
                  src="/images/images/bolt.png"
                  class="absolute -top-12 left-1/2 z-10 h-24 -translate-x-1/2 transition"
                />
                <div
                  class="p-6 md:p-20 w- relative flex h-full flex-col items-center gap-6 overflow-hidden rounded-xl bg-gradient-to-br from-[#FF91ED] to-[#F97D48] shadow-2xl"
                >
                  <div class="ellipse !bottom-1/3 translate-x-2/3" />
                  <div class="ellipse -translate-x-2/3 translate-y-2/3 rotate-[180deg]" />
                  <div>
                    <h3 class="text-style-4xl py-0 text-center leading-[1] !text-white">
                      Grow faster on social media!
                    </h3>
                    <p class="max-w-[50ch] text-center font-light leading-[1.5] text-white">
                      You’re currently using the free version of
                      StreamLadder upgrade and get access to:
                    </p>
                  </div>
                  <ul class="text-style-base flex flex-col gap-1 text-white">
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>1080p 60fps clip quality!</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Get access to all caption styles</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Add zoom effects to your clips</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Stay consistent and schedule your clips</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Upload 1gb clips</span>
                    </li>
                    <li class="items>center flex gap-2">
                      <CheckMarkIcon class="w-4 fill-current" />
                      <span>Create montages</span>
                    </li>
                  </ul>
                  <div class="flex flex-col gap-4 w-full">
                    <Button size="lg" variant="dashboardOverlay" class="w-full"> View all plans</Button>
                    <Button size="sm" variant="link" class="w-full text-white"> View all paid features</Button>
                  </div>
                </div>
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="flex w-full flex-col gap-6">
        <h3 class="text-style-lg text-center">
          Doubtful? Hear from our <span class="text-purple-700">satisfied upgraded users.</span>
        </h3>
        <SenjaTestimonialGrid class="w-full" />
      </div>
    </div>
    <GetQrCode ref="qrModal" />
  </main>
</template>

<style scoped lang="scss">
.bounce-enter-active {
  animation: bounce-in 0.25s;
}

.bounce-leave-active {
  animation: none;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ellipse {
  @apply absolute bottom-0 right-0;
  @apply aspect-square w-full rounded-full;
  @apply bg-gradient-to-tr from-white/40 from-[-15%] to-white/0 to-30%;
}
</style>
