<script lang="ts" setup>
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { computed } from 'vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { Effect, Segment } from '@/areas/editor/@type/Project'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { colorOptions, stringToSemiRandomNumber } from '@/areas/editor/timeline/tracks/stickers/stickerHelpers'
import IconSaxSound from '@/components/Icons/iconsax/IconSaxSound.vue'

const props = defineProps<{
  id: string
}>();

const editorFocusStore = useEditorFocusStore();
const videoStore = useVideoStore();

const effectsStore = useEffectsStore();
const segmentsStore = useSegmentsStore();
const segments = segmentsStore.whereIsNotZoom();

const sound = computed(() => {
  return effectsStore.entities.find((e) => e.id === props.id && e.type === 'sound') as Effect<'sound'>;
});

const isUsedInAnySegment = computed(() => {
  return segments.value.some((segment: Segment) => {
    return sound.value.startMs <= segment.endMs && sound.value.endMs >= segment.startMs;
  });
});

const setFocus = () => {

  const previousFocus = editorFocusStore.focus;
  editorFocusStore.setFocus(FocusTypes.SOUND, sound.value.id);

  if (previousFocus?.key !== sound.value.id) {
    videoStore._currentTime = sound.value.startMs / 1000 + 0.01;
  }

  console.log('setFocus', sound.value.id);
};

const hasFocus = computed(() => {
  return editorFocusStore.focus?.key === sound.value.id;
});

const semiRandomColor = computed(() => colorOptions[Math.floor(stringToSemiRandomNumber(sound.value.id, 0, colorOptions.length))])
</script>

<template>
  <TimeLineTrackNode
    :id="sound.id"
    :end-ms="sound.endMs"
    :max-end-ms="videoStore.durationMs"
    :max-duration-ms="sound.maxDurationMs"
    :min-start-ms="0"
    :start-ms="sound.startMs"
    :style="{
      '--random-bg-color': semiRandomColor.bg,
      '--random-border-color': semiRandomColor.border,
      '--random-handle-color': semiRandomColor.handle,
    }"
    class="group cursor-grab rounded-lg h-full bg-[hsl(var(--random-bg-color))] ring-[hsl(var(--random-handle-color))] hover:ring-4 data-[state=active]:ring-4 hover:z-20 data-[state=active]:z-10 transition-[box-shadow]"
    :class="{ '!bg-gray-400 !ring-gray-600': !isUsedInAnySegment }"
    seek-behavior="seek"
    :active="Boolean(hasFocus)"
    @activated="setFocus"
    @dragStart="setFocus"
    @resizeStart="setFocus"
    @dragStop="(startMs, endMs) => effectsStore.updateTimingsById(sound.id, startMs, endMs)"
    @resizeStop="(startMs, endMs) => effectsStore.updateTimingsById(sound.id, startMs, endMs)"
  >
    <div class="relative flex h-full w-full items-center overflow-hidden rounded-lg text-sm text-white">
      <span class="overflow-hidden mx-3 font-light inline-flex max-h-full w-full select-none flex-row items-center gap-2 whitespace-nowrap py-0.5">
        <span class="w-full flex gap-2 justify-center items-center" >
          <IconSaxSound class="mb-0.5 h-4 w-4" />
          <span>{{ sound.name }}</span>
        </span>
      </span>
    </div>
    <template #handles>
      <div class="flex h-full flex-row gap-0.5 px-1 py-1">
        <div class="bg-gray-800 h-full w-1 rounded" />
        <div class="bg-gray-800 h-full w-1 rounded" />
      </div>
    </template>
  </TimeLineTrackNode>
</template>

<style lang="scss" scoped>

</style>
