import { z } from 'zod'
import { type SocialMediaAccount, useUserInfoStore } from '@/store/user/userInfo'
import {
  PrivacyLevel as privacyLevels,
  type TargetDto,
  YouTubeVisibility,
  YouTubeVisibility as youTubeVisibility
} from '@/apis/streamladder-publisher/model'
import connectionTypes, { mapConnectionTypeToSocialMedia } from '@/enums/connectionTypes'
import { useLocalStorage } from '@/queries/localStorage/useLocalStorage'
import { createSharedComposable } from '@vueuse/core'

const youTubeRegex = new RegExp(/^[^<>]*$/);

// validation rules
export const validationSchema = z
  .object({
    selectedAccounts: z.array(z.string()).min(1, 'Please select at least one account'),
    dateTime: z.date().optional(),
    youtube: z.object({
      visibility: z.enum(Object.values(youTubeVisibility)),
      isChildFriendly: z.boolean(),
      notifySubscribers: z.boolean(),
      title: z
        .string()
        .max(100, { message: 'Title must be less than 100 characters' })
        .min(1)
        .regex(youTubeRegex, '< and > are not allowed'),
      description: z
        .string()
        .max(1250)
        .regex(youTubeRegex, '< and > are not allowed'),
    }).optional(),
    instagram: z.object({
      description: z.string().max(2200),
      shareToFeed: z.boolean(),
      videoCoverTimestampMs: z.number(),
    }).optional(),
    tikTok: z.object({
      title: z.string().max(2000),
      allowDuet: z.boolean(),
      allowStitch: z.boolean(),
      allowComments: z.boolean(),
      privacyLevel: z.enum(Object.values(privacyLevels)),
      isDraft: z.boolean(),
      disclosePostContent: z.boolean(),
      videoCoverTimestampMs: z.number(),
      brandContentToggle: z.boolean(),
      brandOrganicToggle: z.boolean(),
    }).optional(),
  })


export const MarketingHashtags = '#streamladder';

export type ContentPublisherFormState = z.infer<typeof validationSchema>

export const useDefaultSocialMedia = createSharedComposable(() => {
  const userInfo = useUserInfoStore()
  const [initialIds, setInitialIds] = useLocalStorage(
    'default_social-media',
    userInfo.allSocials.map((account) => account.id)
  )

  // validate that the initialIds are still valid for the user
  const validIds = initialIds.value.filter((id) => userInfo.allSocials.some((account) => account.id === id))
  setInitialIds(validIds)

  return [initialIds, setInitialIds] as const
})


// Data transformation
export const toTargetDto = (
  state: z.infer<typeof validationSchema>,
  account: SocialMediaAccount
): TargetDto | undefined => {
  if (account.type === connectionTypes.TIKTOK) {
    return {
      accountId: account.id,
      socialMedia: mapConnectionTypeToSocialMedia(account.type),
      title: state.tikTok?.title?.trim(),
      description: state.tikTok?.title?.trim(),
      tikTokOptions: {
        disableDuet: !state.tikTok?.allowDuet,
        disableStitch: !state.tikTok?.allowStitch,
        disableComment: !state.tikTok?.allowComments,
        privacyLevel: state.tikTok?.privacyLevel,
        // The backend expects an integer, so we round the number
        videoCoverTimestampMs: state.tikTok?.videoCoverTimestampMs 
          ? Math.round(state.tikTok?.videoCoverTimestampMs) 
          : undefined,
        isDraft: state.tikTok?.isDraft,
        brandContentToggle: state.tikTok?.brandContentToggle,
        brandOrganicToggle: state.tikTok?.brandOrganicToggle,
      },
    }
  }

  if (account.type === connectionTypes.INSTAGRAM) {
    return {
      accountId: account.id,
      socialMedia: mapConnectionTypeToSocialMedia(account.type),
      description: state.instagram?.description?.trim(),
      title: '', // Instagram does not have a title field, yet is required by the API.
      instagramOptions: {
        shareToFeed: state.instagram?.shareToFeed,
        videoCoverTimestampMs: state.instagram?.videoCoverTimestampMs,
      },
    }
  }

  if (account.type === connectionTypes.YOUTUBE) {
    return {
      accountId: account.id,
      socialMedia: mapConnectionTypeToSocialMedia(account.type),
      title: state.youtube?.title?.trim(),
      description: state.youtube?.description,
      notifySubscribers: state.youtube?.notifySubscribers,
      youtubeOptions: {
        visibility: state.youtube?.visibility || YouTubeVisibility.Public,
        youTubeChildFriendly: state.youtube?.isChildFriendly ?? false,
        category: '20', // Gaming category. If empty, it falls back to 'People and Blogs' causing possibly bad algorithm.
      },
    }
  }
}

export function deepAssign(target: any, source: any) {
  for (const key in source) {
    if (typeof source[key] === 'object') {
      deepAssign(target[key], source[key])
    } else if (typeof source[key] === 'function') {
      break
    } else {
      target[key] = source[key]
    }
  }
}
