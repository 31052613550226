<script setup lang="ts">
import InstagramSafeZone from '@/components/Editor/SafeZone/InstagramSafeZone.vue'
import TikTokSafeZone from '@/components/Editor/SafeZone/TikTokSafeZone.vue'
import MovableRoot from '@/modules/SLMovable/MovableRoot.vue'
import YoutubeSafeZone from '@/components/Editor/SafeZone/YoutubeSafeZone.vue'
import { ref, computed, watch, provide } from 'vue'
import { useElementSize } from '@vueuse/core'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import type { Size } from '@/modules/SLMovable/@types/Movable'
import { useWorkspaceBoundingContext } from '@/areas/editor/context/workspaceSize'
import MovableCaptionsElement from '@/areas/editor/workspaces/preview/layers/captions/MovableCaptionsElement.vue'
import MovableStickerElement from '@/areas/editor/workspaces/preview/layers/stickers/MovableStickerElement.vue'
import FeedElement from '@/areas/editor/workspaces/preview/cropper/FeedElement.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { useCurrentSegment } from '@/areas/editor/store/useCurrentSegment'
import { useCreateRenderer } from '@/areas/editor/workspaces/preview/usePreviewCanvas'

const props = defineProps<{ 
  enableSnapping: boolean;
  safeZones?: [{ key: 'youtube'; show: boolean }, { key: 'tiktok'; show: boolean }, { key: 'instagram'; show: boolean }]
}>()

const segment = useCurrentSegment()
const layoutId = computed(() => segment.value.layoutId)

const cropsStore = useCropsStore()
const crops = cropsStore.whereLayoutIdIs(layoutId)

function computeCropArea(size: Size) {
  const cropSize = { width: size.width * containerWidth.value, height: size.height * containerHeight.value }
  return cropSize.width * cropSize.height
}

const sortedCrops = computed(() => {
  return [...crops.value]
    .sort((a, b) => computeCropArea(b.feedData) - computeCropArea(a.feedData))
    .map(crop => crop.id)
})

const container = ref<HTMLElement | null>(null)
const { width: containerWidth, height: containerHeight } = useElementSize(container)

const showYoutubeSafeZone = computed(() => props.safeZones?.find((zone) => zone.key === 'youtube')?.show)
const showTiktokSafeZone = computed(() => props.safeZones?.find((zone) => zone.key === 'tiktok')?.show)
const showInstagramSafeZone = computed(() =>  props.safeZones?.find((zone) => zone.key === 'instagram')?.show)

const stickersStore = useStickersStore()
const sortedStickers = computed(() => {
  return [...stickersStore.entities]
    .sort((a, b) => computeCropArea(b.area) - computeCropArea(a.area));
})

const videoContainer = ref<HTMLDivElement | null>(null)
useCreateRenderer(videoContainer, containerWidth, containerHeight)

provide('editorWrapper', container)
const movable = ref<InstanceType<typeof MovableRoot> | null>(null)
const { width: workspaceWidth, height: workspaceHeight, top: workspaceTop, left: workspaceLeft } = useWorkspaceBoundingContext()!
const { isLayoutsStep } = useEditorStep()
watch([workspaceWidth, workspaceHeight, isLayoutsStep], () => {
  movable.value?.resize()
})
</script>

<template>
  <section ref="container" class="absolute inset-0 grid grid-rows-1 grid-cols-1">
    <div ref="videoContainer" class="col-start-1 row-start-1 h-full w-full rounded-lg overflow-hidden pointer-events-none" />
    <MovableRoot 
      ref="movable" class="col-start-1 row-start-1 h-full w-full" 
      :container-selector="'#workspace'"
      :container-x="workspaceLeft" :container-y="workspaceTop"
    >
      <div id="sticker-layer" class="absolute inset-0 rounded overflow-visible" />
      <FeedElement v-for="id in sortedCrops" :id="id" :key="id" :enable-snapping="enableSnapping" />
      <MovableCaptionsElement :snapping-enabled="enableSnapping" />
      <MovableStickerElement v-for="sticker in sortedStickers" :key="sticker" :id="sticker.id" :snapping-enabled="enableSnapping" />
    </MovableRoot>
    <TikTokSafeZone class="h-full w-full col-start-1 row-start-1" v-show="showTiktokSafeZone" />
    <YoutubeSafeZone class="h-full w-full col-start-1 row-start-1" v-show="showYoutubeSafeZone" />
    <InstagramSafeZone class="h-full w-full col-start-1 row-start-1" v-show="showInstagramSafeZone" />
  </section>
</template>

<style scoped lang="scss">

</style>
