<script setup lang="ts">
import IconSaxCloseSquare from '@/components/Icons/iconsax/IconSaxCloseSquare.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import useCreateSocialConnection from '@/Hooks/useCreateSocialConnection'
import type { ConnectionType } from '@/enums/connectionTypes'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { Button } from '@/components/ui/button'
import { toRef, ref } from 'vue'
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'
import { canGuard } from '@/Hooks/useGuard'
import { useUserInfoStore } from '@/store/user/userInfo'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import AlertBox from '@/components-v2/data-display/AlertBox.vue'
import InstagramIcon from "@/components/Icons/SocialMedia/InstagramIcon.vue";
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import FacebookIcon from '@/components/Icons/FacebookIcon.vue'

const props = defineProps<{ modelValue: boolean }>()
const emit = defineEmits<{ (name: 'update:modelValue', value: boolean): void }>()

function close() {
  emit('update:modelValue', false)
}

const userInfoStore = useUserInfoStore()

const connectionError = ref<string | null>(null)
const { connect } = useCreateSocialConnection()
function createConnection(type: ConnectionType) {
  connectionError.value = null
  const accountsOfType = userInfoStore.connections[type].length
  if (accountsOfType >= 1 && !canGuard('multiple-publishing-accounts')) {
    upgradeDialog.open('multiple-publishing-accounts')
  } else {
    connect(type).then(close).catch((error) => {
      connectionError.value = error
    })
  }
}

useConditionalEventListener(toRef(props, 'modelValue'), 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close()
  }
})

const instagramEnabled = useFeatureFlagEnabled('instagram')

const isInstagramConnectionDialogOpen = ref(false);
const createInstagramConnection = () => {
  isInstagramConnectionDialogOpen.value = false;
  createConnection('instagram');
};

const checkedUnderstand = ref(false);
</script>

<template>
  <teleport to="body">
    <section class="modal modal-bottom z-[1] md:modal-middle" :class="{ 'modal-open': modelValue }" @click="close">
      <article class="layer-1 modal-box flex !max-w-fit flex-col gap-8" @click.stop>
        <div class="flex flex-row items-start justify-between gap-4 text-center">
          <div class="w-10 shrink-0" />

          <header class="flex flex-col gap-1">
            <h3 class="m-0 p-0 text-2xl md:text-3xl">Connect a new social media account</h3>
            <p class="text-sm font-light text-opacity-50">
              Please choose to which platform you want to start publishing content
            </p>
          </header>

          <button
            @click="close"
            class="rounded-xl bg-transparent p-2 transition-all hover:bg-zinc-500/50 active:scale-90"
          >
            <IconSaxCloseSquare />
          </button>
        </div>

        <AlertBox v-if="connectionError" use-icon variant="error" title="Connection failed" :message="connectionError" />

        <nav class="flex flex-col md:flex-row gap-4">
          <button
            @click="createConnection('youtube')"
            class="layer-2 group flex md:aspect-square p-8 flex-auto flex-col items-center justify-center gap-6 rounded-lg transition-all hover:scale-[1.015] hover:shadow-lg active:scale-90 active:shadow-none min-w-[300px]"
          >
            <span class="relative -mr-3 flex h-16 w-16 cursor-pointer text-zinc-900">
              <span class="grid w-full place-items-center rounded-full border border-current transition-all">
                <YoutubeIcon class="h-8 w-8 text-special-youtube dark:text-white" />
              </span>
              <span
                class="absolute -right-3 -bottom-1 grid h-7 w-7 place-items-center rounded-full bg-purple-600 transition-all"
              >
                <IconSaxAdd class="h-4 w-4 text-white transition-all" />
              </span>
            </span>
            <GradientButton class="group-hover:shadow-gradient">
              Connect YouTube
              <IconSaxAdd />
            </GradientButton>
          </button>

          <button
            @click="createConnection('tiktok')"
            class="layer-2 group flex md:aspect-square p-8 flex-auto flex-col items-center justify-center gap-6 rounded-lg transition-all hover:scale-[1.015] hover:shadow-lg active:scale-90 active:shadow-none min-w-[300px]"
          >
            <span class="relative -mr-3 flex h-16 w-16 cursor-pointer text-zinc-900">
              <span class="grid w-full place-items-center rounded-full border border-current transition-all">
                <TikTokIcon class="h-8 w-8" />
              </span>
              <span
                class="absolute -right-3 -bottom-1 grid h-7 w-7 place-items-center rounded-full bg-purple-600 transition-all"
              >
                <IconSaxAdd class="h-4 w-4 text-white transition-all" />
              </span>
            </span>
            <GradientButton class="group-hover:shadow-gradient">
              Connect TikTok
              <IconSaxAdd />
            </GradientButton>
          </button>

          <button
            v-if="instagramEnabled"
            @click="isInstagramConnectionDialogOpen = true"
            class="relative layer-2 group flex md:aspect-square p-8 flex-auto flex-col items-center justify-center gap-6 rounded-lg transition-all hover:scale-[1.015] hover:shadow-lg active:scale-90 active:shadow-none min-w-[300px]"
          >
            <span class="absolute top-2.5 left-2.5 rounded-full font-semibold text-xs uppercase px-2 py-1 bg-green-700 text-background">
              BETA
            </span>

            <span class="relative -mr-3 flex h-16 w-16 cursor-pointer text-zinc-900">
              <span class="grid w-full place-items-center rounded-full border border-current transition-all">
                <instagram-icon class="h-8 w-8" />
              </span>
              <span
                class="absolute -right-3 -bottom-1 grid h-7 w-7 place-items-center rounded-full bg-purple-600 transition-all"
              >
                <IconSaxAdd class="h-4 w-4 text-white transition-all" />
              </span>
            </span>
            <GradientButton class="group-hover:shadow-gradient">
              Connect Instagram
              <IconSaxAdd />
            </GradientButton>
          </button>
        </nav>
      </article>
    </section>

    <Dialog v-model:open="isInstagramConnectionDialogOpen">
      <DialogContent class="max-w-2xl">
        <div class="flex flex-col items-center justify-center gap-4 text-center">
          <InstagramIcon class="h-16 w-16" />

          <h3 class="text-3xl">Connect an Instagram account</h3>
          <p class="font-light">
            You'll need to connect and authorize StreamLadder to your <span class="font-bold">Facebook Business</span> account
          </p>

          <div class="w-full px-1 md:px-6 py-2">
            <div class="w-full rounded-lg bg-yellow-50 border border-yellow-400 p-2 py-4 md:px-4 md:py-6 text-sm font-light flex gap-2 justify-center items-center">
              <FacebookIcon class="h-12 w-12 text-[#277DFF]" />
              <div class="w-full flex flex-col gap-2 px-4">
                <p class="text-left dark:text-black">To post from StreamLadder to Instagram, you need an <span class="font-bold">Instagram Professional or Business account</span> linked to a <span class="font-bold">Facebook Business Account</span>. Check our guide below if you haven't already.</p>
                <Button
                  as="a"
                  href="https://streamladder.com/user-guide/a-complete-guide-on-how-to-connect-your-instagram-to-streamladder"
                  target="_blank"
                  variant="link"
                  size="sm"
                  class="px-0 justify-start max-w-full truncate text-blue-500 underline hover:text-blue-300 transition-[color]"
                >
                  How to connect your Instagram to StreamLadder
                </Button>
              </div>
            </div>
          </div>

          <label class="label cursor-pointer justify-start">
            <input v-model="checkedUnderstand" class="checkbox rounded-sm mr-2 h-5 w-5 dark:border-white" type="checkbox" />
            <span class="label-text text-foreground font-light mt-0.5">I've read and understood the guide above</span>
          </label>

          <GradientButton class="mt-2" @click="createInstagramConnection" :disabled="!checkedUnderstand">
            Connect Instagram
            <IconSaxAdd />
          </GradientButton>
        </div>
      </DialogContent>
    </Dialog>
  </teleport>
</template>

<style scoped lang="scss"></style>
