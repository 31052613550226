<script lang="ts" setup>
import TimelineRoot from '@/modules/SLTimeline/TimelineRoot.vue'
import TimelineContainer from '@/modules/SLTimeline/TimelineContainer.vue'
import Timeline from '@/modules/SLTimeline/Timeline.vue'
import { useProvideTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { computed, ref, watch, reactive, onMounted } from 'vue'
import TimeLinePlayHead from '@/modules/SLTimeline/TimeLinePlayHead.vue'
import { useRafFn, watchDeep, useElementSize, useEventListener } from '@vueuse/core'
import TimelineHeader from '@/areas/editor/timeline/header/TimelineHeader.vue'
import TimeLineDialsTrack from '@/modules/SLTimeline/TimeLineDialsTrack.vue'
import { useEditorFocusStore, type FocusType } from '@/store/editor/editorFocus'
import { onKeyStroke } from '@vueuse/core/index'
import TimelineFooter from '@/areas/editor/timeline/footer/TimelineFooter.vue'
import { isInputTarget, isForeignTarget, isInputTargetOrRangeSelection } from '@/areas/editor/timeline/helpers'
import CaptionTrack from '@/areas/editor/timeline/tracks/captions/CaptionTrack.vue'
import ZoomTrack from '@/areas/editor/timeline/tracks/zooms/ZoomTrack.vue'
import SegmentTrack from '@/areas/editor/timeline/tracks/segments/SegmentTrack.vue'
import StickerTrack from '@/areas/editor/timeline/tracks/stickers/StickerTrack.vue'
import FlatSegmentsTrack from '@/areas/editor/timeline/tracks/segments/FlatSegmentsTrack.vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { minBy, clamp } from 'lodash-es'
import { useStripPunctuation } from '@/components/Captions/useStripPunctuation'
import IconSaxHambergerMenu from '@/components/Icons/iconsax/IconSaxHambergerMenu.vue'
import SoundTrack from '@/areas/editor/timeline/tracks/sounds/SoundTrack.vue'

const props = defineProps<{
  step: 'layout' | 'crop' | 'editor'
  defaultOpen?: boolean
}>()

const videoStore = useVideoStore()
const editorFocusStore = useEditorFocusStore()

const totalDurationMs = computed(() => videoStore._duration * 1000)

const updateFunction = () => {
  updateWordsTimer(videoStore.getExactTime())
}

const { pause, resume } = useRafFn(updateFunction, { immediate: false })

const resumeVideoAndTimeline = () => {
  resume()
  videoStore.playing = true
}

const pauseVideoAndTimeline = () => {
  pause()
  videoStore.playing = false
}

watchDeep(() => editorFocusStore.focus, () => {
  updateWordsTimer(videoStore.getExactTime() + 0.001)
  autoScroll(videoStore.getExactTime() + 0.1 - pxToMs(0.5 * containerWidth.value))
})

let wasPlaying = false
const commitSeek = (ms: number, scrubbing = false) => {
  videoStore.scrubbing = scrubbing
  if (scrubbing && videoStore.playing) {
    wasPlaying = true
    videoStore.playing = false
  }
  if (!scrubbing && wasPlaying) {
    videoStore.playing = true
    wasPlaying = false
  }
  currentTimeMs.value = ms
  videoStore._currentTime = ms / 1000
}

const editorCaptionsStore = useEditorCaptionsStore()
const words = computed(() => editorCaptionsStore.groupedCaptions.flatMap(c => c.words))

const strip = useStripPunctuation()
const {
  zoomLevel,
  autoScroll, pxToMs, containerWidth,
  triggerSeek,
  open: timelineOpen,
} = useProvideTimelineStore(totalDurationMs, {
  minVisibleMs: 500,
  onSeek: commitSeek,
  openDefault: props.defaultOpen,
  maxTimelineWidth: computed(() => {
    const shortest = minBy(words.value, w => strip.value(w).length)
    if (shortest) {
      const width = shortest.text.length * 24 + 75
      return width / (shortest.end - shortest.start) * totalDurationMs.value
    } else {
      return null
    }
  })
})

watch(
  () => videoStore.playing,
  (value) => {
    value && !videoStore.scrubbing && !videoStore.preservedPaused
      ? resumeVideoAndTimeline()
      : pauseVideoAndTimeline()
  },
)

onKeyStroke(['Backspace', 'Delete', 'Del'], (e) => {
  if (isInputTargetOrRangeSelection(e)) return
  editorFocusStore.deleteFocusModel()
})

onKeyStroke(['s'], async (e) => {
  if (isInputTarget(e)) return
  editorFocusStore.splitFocusModel(currentTimeMs.value)
})

onKeyStroke([' '], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  if (isForeignTarget(e)) return

  e.preventDefault()
  videoStore.preservedPaused = !videoStore.preservedPaused
  videoStore.playing = !videoStore.playing
})

onKeyStroke(['m'], (e) => {
  if (isInputTarget(e)) return
  videoStore.muted = !videoStore.muted
})

const currentTimeMs = ref(0)
watch(() => videoStore.currentTimeMs, () => {
  currentTimeMs.value = videoStore.currentTimeMs
})

const updateWordsTimer = (currentTime: number) => {
  if (videoStore.seeking) return
  if (currentTime !== currentTimeMs.value) {
    currentTimeMs.value = currentTime
    if (!videoStore.scrubbing) {
      autoScroll(currentTimeMs.value)
    }
  }
}

const dialStepSize = computed(() => {
  return zoomLevel.value < 0.5
    ? 500
    : 1000
})

const dialNumberInterval = computed(() => {
  return zoomLevel.value < 0.5
    ? 10
    : 5
})

const inputBindings = reactive(videoStore.currentTimeBindings)

const container = ref<InstanceType<typeof TimelineContainer>>()
const { height: timelineHeight } = useElementSize(computed(() => container.value?.$el))

const minimalTimelineHeight = 130;
const resizeableTimelineHeight = ref(minimalTimelineHeight);

onMounted(() => {
  const savedHeight = localStorage.getItem('timelineHeight');
  if (savedHeight && !isNaN(parseInt(savedHeight))) {
    resizeableTimelineHeight.value = parseInt(savedHeight);
  }
});

const isDragging = ref(false);
const startY = ref(0);

const onTouchStart = (e: TouchEvent) => {
  startY.value = e.touches[0].clientY;
  isDragging.value = true;
  document.addEventListener('touchmove', onTouchMove);
  document.addEventListener('touchend', onTouchEnd);
  document.body.style.userSelect = 'none';
};

const onTouchMove = (e: TouchEvent) => {
  if (isDragging.value) {
    const diff = e.touches[0].clientY - startY.value;
    startY.value = e.touches[0].clientY;
    resizeableTimelineHeight.value = resizeableTimelineHeight.value - diff;
  }
};

const onTouchEnd = () => {
  localStorage.setItem('timelineHeight', resizeableTimelineHeight.value.toString());
  isDragging.value = false;
  document.removeEventListener('touchmove', onTouchMove);
  document.body.style.userSelect = '';
};

const onMouseDown = (e: MouseEvent) => {
  startY.value = e.clientY;
  isDragging.value = true;
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
  document.body.style.userSelect = 'none';
};

const onMouseMove = (e: MouseEvent) => {
  if (isDragging.value) {
    const diff = e.clientY - startY.value;
    startY.value = e.clientY;
    resizeableTimelineHeight.value = Math.max(minimalTimelineHeight, resizeableTimelineHeight.value - diff);
  }
};

const onMouseUp = () => {
  localStorage.setItem('timelineHeight', resizeableTimelineHeight.value.toString());
  isDragging.value = false;
  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
  document.body.style.userSelect = '';
};

const playHeadTranslateY = ref(0);
const offsetTimelinePlayHead = (e: Event) => {
  if (e.target instanceof HTMLElement) {
    playHeadTranslateY.value = e.target.scrollTop;
  }
};

const frametime = computed(() => 1000 / videoStore.framerate)
const isFocussedOnMovable = computed(() => {
  const focusType = editorFocusStore.focus?.type as FocusType | undefined
  switch (focusType) {
    case 'crop':
    case 'sticker':
    case 'caption':
    case 'textsticker':
      return true
    default:
      return false
  }
})
useEventListener('keydown', (event) => {
  if (!isInputTargetOrRangeSelection(event) && !isFocussedOnMovable.value && !videoStore.playing) {
    if (event.key === 'ArrowRight') {
      const nextFrame = clamp(videoStore.currentTimeMs + frametime.value, 0, totalDurationMs.value)
      triggerSeek(nextFrame)
    } else if (event.key === 'ArrowLeft') {
      const nextFrame = clamp(videoStore.currentTimeMs - frametime.value, 0, totalDurationMs.value)
      triggerSeek(nextFrame)
    }
  }
})
</script>

<template>
  <TimelineRoot
    class="flex layer-1 flex-col border-surface-input-border transition-[height,_margin-bottom]"
    :style="{ marginBottom: timelineOpen ? '0' : `-80px` }"
  >
    <div
      v-if="!timelineOpen"
      class="relative flex h-2 md:h-1.5 origin-bottom transform border-transparent transition-[height] hover:h-2.5"
    >
      <div class="absolute inset-0 bg-zinc-100" />
      <div :style="{ width: inputBindings.progress + '%' }" class="absolute inset-y-0 left-0 bg-company-primary-100" />
      <input
        v-model="inputBindings.model"
        class="absolute inset-0 w-full overflow-hidden opacity-0 cursor-pointer"
        type="range"
        v-bind="inputBindings"
        @mousedown="inputBindings.onMouseDown"
        @mouseup="inputBindings.onMouseUp"
      />
    </div>

    <div
      v-if="timelineOpen"
      @mousedown="onMouseDown"
      @touchstart.passive="onTouchStart"
      class="hidden group md:flex justify-center items-center h-1 cursor-ns-resize hover:bg-brand-state-hover-border transition-[background-color]"
      :class="{ '!bg-brand-state-hover-border': isDragging }"
    >
      <div
        :class="{ '!bg-brand-state-hover-border !px-10': isDragging }"
        class="absolute bg-surface-panel px-6 h-5 rounded-t-lg -mt-[25px] border border-surface-panel-border border-b-0 group-hover:bg-brand-state-hover-border group-hover:px-10 transition-[background-color,padding]"
      >
        <IconSaxHambergerMenu
          :class="{ '!text-white': isDragging }"
          class="-mt-1 w-4 h-4 group-hover:text-white dark:group-hover:text-black transition-[color]"
        />
      </div>
    </div>

    <TimelineHeader />

    <div
      ref="container"
      class="relative flex w-full flex-col overflow-y-hidden border-t border-surface-panel bg-[#FBF9F9] dark:bg-gray-950 cursor-col-resize outline-0"
    >
      <TimelineContainer
        @scroll="offsetTimelinePlayHead"
        class="md:min-h-[80px] max-h-[30dvh] md:max-h-[50dvh] pt-4 w-full overflow-y-auto overflow-x-auto flex flex-col"
        :class="{ 'overflow-x-hidden': zoomLevel === 1 }"
        :style="{ height: timelineOpen ? resizeableTimelineHeight + 'px' : '0px' }"
      >
        <Timeline
          :class="{ 'hidden md:flex': !timelineOpen }"
          class="py-2 flex flex-col gap-2 outline-0 w-full my-auto !static"
        >
          <TimeLineDialsTrack
            :duration="videoStore._duration!"
            :number-interval="dialNumberInterval"
            :stepSize="dialStepSize"
            class="absolute top-0 h-4 w-full text-xs font-normal shrink-0"
            :class="{ '!hidden': !timelineOpen }"
          />
          <StickerTrack />
          <SoundTrack />
          <CaptionTrack />
          <ZoomTrack />
          <SegmentTrack />
          <FlatSegmentsTrack v-if="false" />
          <TimeLinePlayHead
            :current-time="currentTimeMs"
            class="no-drag absolute pointer-events-none inset-y-0 w-[3px] box-content px-[12px] z-20 flex flex-col -translate-x-1/2 transform"
          >
            <div
              class="h-full w-full bg-gray-950 dark:bg-gray-200"
              :style="{ transform: `translateY(${playHeadTranslateY}px)` }"
            />
          </TimeLinePlayHead>
        </Timeline>
      </TimelineContainer>
    </div>
    <TimelineFooter v-if="timelineOpen" class="md:hidden" />
  </TimelineRoot>
</template>

<style lang="scss" scoped></style>
