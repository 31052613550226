import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { tiers } from '@/enums/tiers'
import type { Component } from 'vue'
import { productIds } from '@/data/_productIds'

const plans = {
  free: {
    title: 'Free',
    subtitle: 'Everything you need to get started',
    tagline: 'Get started',
    key: 'free',
    tier: tiers.FREE,
    highlighted: false,
    badge: null,
    features: ['Export in 720p / 30fps', 'Convert unlimited videos', 'No watermark'],
    includes: '&nbsp;',
    plans: [],
  },
  silver: {
    title: 'Silver',
    key: 'silver',
    tier: tiers.SILVER,
    subtitle: 'Streamline your editing process',
    tagline: 'Accelerate your growth',
    highlighted: false,
    badge: SilverPlanButton,
    features: ['Export in 1080p / 60fps', 'Add stickers & text', 'AI-powered captions'],
    includes: 'Everything in Free',
    plans: [
      {
        key: 'year',
        paddle: productIds.SILVER.YEARLY,
        data: {},
        paddleData: undefined,
        name: 'Yearly',
        interval: '/year',
        save: {
          title: '20%',
          styles: 'bg-company-primary-muted text-white',
        },
      },
      {
        key: 'month',
        paddle: productIds.SILVER.MONTHLY,
        data: {},
        paddleData: undefined,
        interval: '/month',
        name: 'Monthly',
        save: null,
      },
      {
        key: 'plus-month',
        paddle: productIds.PLUS.MONTHLY,
        data: {},
        paddleData: undefined,
        interval: '/month',
        name: 'Monthly',
        save: null,
      },
      {
        key: 'plus-yearly',
        paddle: productIds.PLUS.YEARLY,
        data: {},
        paddleData: undefined,
        interval: '/year',
        name: 'Yearly',
        save: null,
      },
    ],
  },
  gold: {
    title: 'Gold',
    subtitle: 'Unlock professional features',
    tagline: 'Become a pro',
    key: 'gold',
    tier: tiers.GOLD,
    highlighted: true,
    badge: GoldPlanButton,
    features: [
      'Create montages',
      'Schedule clips directly to social media',
      'Zoom & Sound effects',
      'Ultra fast rendering',
      'Animated Giphy stickers',
    ],
    includes: 'Everything in Silver',
    plans: [
      {
        key: 'year',
        paddle: productIds.GOLD.YEARLY,
        data: {},
        paddleData: undefined,
        name: 'Yearly',
        interval: '/year',
        save: {
          title: '20%',
          styles: 'bg-company-primary-muted text-white',
        },
      },
      {
        key: 'month',
        paddle: productIds.GOLD.MONTHLY,
        data: {},
        paddleData: undefined,
        interval: '/month',
        name: 'Monthly',
        save: null,
      },
    ],
  },
} as const satisfies ProductData

export default plans

export interface ProductData {
  free: Tier
  silver: Tier
  gold: Tier
}

export type TierLevel = keyof ProductData

export interface Tier {
  title: string
  key: TierLevel
  tier: number
  subtitle: string
  tagline: string
  highlighted: boolean
  badge: Component | null
  features: string[]
  includes: string
  plans: Plan[]
}

export interface Plan {
  key: string
  paddle: number
  data: Record<never, never>
  name: string
  interval: string
  save: { title: string; styles: string } | null
  paddleData?: PaddleProduct
}
