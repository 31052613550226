<script setup lang="ts">
import { DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import IconSaxSearchNormal1 from '@/components/Icons/iconsax/IconSaxSearchNormal1.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { ref, onMounted } from 'vue'
import { uniq } from 'lodash-es'
import { useLocalStorage } from '@vueuse/core'
import type { VodDTO } from '@/apis/streamladder-api/model'
import { getApiTwitchVods } from '@/apis/streamladder-api/twitch-vods/twitch-vods'
import { Skeleton } from '@/components/ui/skeleton'
import AppImage from '@/areas/editor/components/AppImage.vue'
import { differenceInCalendarDays } from 'date-fns'
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'

const emit = defineEmits<{
  (event: 'select', vod: VodDTO): void
}>()

const userInfoStore = useUserInfoStore()
const searchQuery = ref(userInfoStore.userName)

const recentSearches = useLocalStorage<string[]>('searchHistory', [])
const vods = ref<VodDTO[]>([])

async function search(streamerName: string) {

  if (streamerName.trim()) {

    searchQuery.value = streamerName
    recentSearches.value = uniq([...recentSearches.value.filter(v => v !== streamerName), streamerName]).filter(v => !!v.trim())
    
    const response = await getApiTwitchVods({ streamerName })
    vods.value = response ?? []
  }
}

const isLoading = ref(false)
async function searchWithLoadingState(streamerName: string) {
  isLoading.value = true
  await search(streamerName)
  isLoading.value = false
}

onMounted(() => {
  searchWithLoadingState(searchQuery.value)
})
</script>

<template>
  <DialogHeader>
    <DialogTitle>
      Browsing past streams
    </DialogTitle>
    <DialogHeader>
      <p class="text-sm text-brand-state-text-secondary">
        Search for a Twitch username to browse their streams
      </p>
    </DialogHeader>
  </DialogHeader>
  <form @submit.prevent="searchWithLoadingState(searchQuery)" class="rounded-lg shadow-inner border border-surface-panel-border bg-background flex items-center focus-within:outline-brand-primary-dim2 focus-within:outline-offset-4 focus-within:outline outline-transparent transition-[outline] h-12">
    <input 
      v-model="searchQuery"
      @blur="searchWithLoadingState(searchQuery)"
      @paste="searchWithLoadingState(searchQuery)"
      class="w-full border-none bg-transparent h-full !outline-none px-4" 
      placeholder="Enter a Twitch username"
    />
    <Button type="submit" class="flex-1 rounded-l-none active:rounded-l-lg rounded-r-md h-full active:h-10">
      <IconSaxSearchNormal1 />
      Search
    </Button>
  </form>
  
  <header>
    <h3 class="text-lg font-bold mt-4">Recent searches</h3>
    <template v-for="(search, i) in recentSearches" :key="search">
      <button class="text-brand-primary-dim1 underline hover:no-underline" @click="searchWithLoadingState(search)">
        {{ search }}
      </button>
      <template v-if="i < recentSearches.length - 1">, </template>
    </template>
  </header>
  
  <template v-if="isLoading">
    <div class="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4 mt-4">
      <div v-for="i in 12" :key="i" class="flex flex-col">
        <Skeleton class="w-full aspect-video mb-2" />
        <Skeleton class="w-3/4 h-6 mb-1" />
        <Skeleton class="w-1/2 h-4" />
      </div>
    </div>
  </template>
  <template v-else-if="vods.length > 0">
    <div class="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4 mt-4">
      <template v-for="vod in vods" :key="vod.id">
        <div class="flex flex-col group cursor-pointer" @click="emit('select', vod)">
          <div class="rounded-xl w-full aspect-video overflow-hidden relative">
            <AppImage class="rounded-xl w-full aspect-video mb-2 group-hover:scale-110" :src="vod.thumbnailUrl!.replace('%{width}', '480').replace('%{height}', '270')" :alt="vod.title + ' thumbnail'" />
            <div class="opacity-0 invisible group-hover:visible group-hover:opacity-100 absolute inset-0 w-full h-full grid place-items-center bg-black/20">
              <Button variant="gradient">
                Select stream <IconSaxMagicpen class="w-4 h-4" />
              </Button>
            </div>
          </div>
          <p class="text-sm font-semibold truncate">{{ vod.title }}</p>
          <p class="text-xs text-brand-state-text-secondary">
            {{ differenceInCalendarDays(new Date(), new Date(vod.createdAt!)) }} days ago
          </p>
        </div>
      </template>
    </div>
  </template>
</template>

<style scoped lang="scss">

</style>
