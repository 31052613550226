/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { ApiError, GetApiUsersUserIdRewardsParams, RewardDtoPageApiResponse } from '.././model'
import { accountsAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getApiUsersUserIdRewards = (
  userId: MaybeRef<string>,
  params?: MaybeRef<GetApiUsersUserIdRewardsParams>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  userId = unref(userId)
  params = unref(params)

  return accountsAxiosInstance<RewardDtoPageApiResponse>(
    { url: `/api/users/${userId}/rewards`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetApiUsersUserIdRewardsQueryKey = (
  userId: MaybeRef<string>,
  params?: MaybeRef<GetApiUsersUserIdRewardsParams>
) => {
  return ['api', 'users', userId, 'rewards', ...(params ? [params] : [])] as const
}

export const getGetApiUsersUserIdRewardsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUsersUserIdRewards>>,
  TError = ApiError
>(
  userId: MaybeRef<string>,
  params?: MaybeRef<GetApiUsersUserIdRewardsParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdRewards>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUsersUserIdRewardsQueryKey(userId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersUserIdRewards>>> = ({ signal }) =>
    getApiUsersUserIdRewards(userId, params, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: computed(() => !!unref(userId)),
    staleTime: 60000,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdRewards>>, TError, TData>
}

export type GetApiUsersUserIdRewardsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsersUserIdRewards>>>
export type GetApiUsersUserIdRewardsQueryError = ApiError

export const useGetApiUsersUserIdRewards = <
  TData = Awaited<ReturnType<typeof getApiUsersUserIdRewards>>,
  TError = ApiError
>(
  userId: MaybeRef<string>,
  params?: MaybeRef<GetApiUsersUserIdRewardsParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdRewards>>, TError, TData>>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUsersUserIdRewardsQueryOptions(userId, params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
