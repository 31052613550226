import { useUserInfoStore } from '@/store/user/userInfo'
import logging from '@/logging'
import EventBus from '@/eventBus'
import upgradeEvents from '@/events/upgradeEvents'
import { getInterval, getTier } from '@/store/products'
import { tiers } from '@/enums/tiers'
import mainEvents from '@/events/mainEvents'
import * as Sentry from '@sentry/vue'

export function useCreateSubscription() {

  const userInfoStore = useUserInfoStore()

  async function createSubscription(data: Record<string, string | number>, reasonOpened: string) {

    const eventMeta = {
      interval: getInterval(data['product'] as number),
      tier: getTier(data['product'] as number),
    }

    logging.trackEvent('Payment Dialog Opened', {
      reasonOpened,
      passthrough: `{"userId": "${userInfoStore.userId}"}`,
      ...eventMeta,
    });

    if (!userInfoStore.userId) {
      console.error('User has no User ID while upgrading', JSON.stringify(userInfoStore), eventMeta);
      Sentry.captureException(new Error('Payment Error: User has no User ID while upgrading'));
      throw new Error('User ID is not provided. Without a User ID, the checkout process cannot associate the current user with the purchase, resulting in potential loss of data and transaction failure.')
    }

    await new Promise<void>(async (resolve) => {
      if (import.meta.env.DEV) {
        await tierHasBeenUpdated(eventMeta, resolve)
        resolve()
      } else {
        // eslint-disable-next-line
        // @ts-ignore
        window.Paddle.Checkout.open({
          ...data,
          email: userInfoStore.email,
          title: 'StreamLadder Silver / Gold',
          passthrough: `{"userId": "${userInfoStore.userId}"}`,
          successCallback: () => {
            // Tier may not be updated immediately in our database, so we retry until it is
            if (userInfoStore.tier === 0) {
              console.log('Tier has been upgraded')
              tierHasBeenUpdated(eventMeta, resolve)
            } else {
              userInfoStore.updateUserInfo()
              // In theory this should never happen, but just in case...
              logging.trackEvent('Payment Dialog Success', eventMeta)
              EventBus.$emit(upgradeEvents.UPGRADE_SUCCESS)
              resolve()
            }
          },
          closeCallback: () => {
            logging.trackEvent('Payment Dialog Closed', eventMeta)
            resolve()
          },
          eventCallback: (data: any) => {

            if (data.event === 'Checkout.Failed') {
              logging.trackEvent('Payment Dialog Failed', data);
              EventBus.$emit(mainEvents.ERROR, "It appears you're trying to upgrade from a sanctioned country. We can't upgrade your account because our payment provider cannot handle payments from your country. If you have any questions please contact support.");
              resolve();
            }

            if (data.event === 'Checkout.Error') {
              console.error(JSON.stringify(data));
              logging.trackEvent('Payment Dialog Failed', data);
              Sentry.captureException(new Error('Payment Error: Generic error from Paddle'), data);
              EventBus.$emit(mainEvents.ERROR, 'Something went wrong with upgrading your account. Please try again or contact support.');
              resolve();
            }
          },
        })
      }
    })
  }

  const tierHasBeenUpdated = async (eventMeta: Record<string, string> & { tier: 0 | 10 | 100 }, resolve: () => void, attempt = 0) => {

    await asyncTimeout(0)
    await userInfoStore.updateUserInfo()

    if (userInfoStore.tier !== tiers.fromString(eventMeta.tier) && attempt < 3) {
      await tierHasBeenUpdated(eventMeta, resolve, attempt + 1)
    } else {
      logging.trackEvent('Payment Dialog Success', eventMeta)
      EventBus.$emit(upgradeEvents.UPGRADE_SUCCESS)
      resolve()
    }
  }

  return { createSubscription }
}

const asyncTimeout = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
