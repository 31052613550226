<template>
  <div
    class="page light flex min-h-screen flex-col gap-2 bg-[#f9f6fe] md:gap-4"
    :style="`--footer-height: ${footerHeight}px`"
  >
    <div :class="{ 'sticky top-0 z-[1] w-full': fixedHeader }">
      <UpgradeBanner @openUpgradeDialog="openUpgradeDialog" />
      <div class="purple-bg h-16" :class="headerSize">
        <div v-if="headerSize !== 'small'" class="wave" />
      </div>
      <header class="z-30" id="main-header">
        <!-- vue3 cannot detect empty slots that neatly, so use this crude solution -->
        <NavbarApplication v-if="!!$slots['sub-nav']"><slot name="sub-nav" /></NavbarApplication>
        <NavbarApplication v-else />
      </header>
    </div>
    <div
      class="container mx-auto flex flex-grow items-start"
      :class="[`flex-${contentDirection} ${contentContainerClass}`, { 'z-0': fixedHeader }]"
    >
      <slot name="content" />
    </div>
    <footer
      ref="footer"
      class="sticky bottom-0 z-20 border-t-2 border-gray-100 bg-white lg:sticky lg:w-full"
      v-if="hasFooter"
    >
      <div class="relative mx-auto flex h-full items-center justify-end">
        <slot name="footer" />
      </div>
    </footer>
  </div>
</template>

<script>
import NavbarApplication from '@/components-v2/navigation/NavbarApplication.vue'
import { ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import UpgradeBanner from '@/components-v2/navigation/UpgradeBanner.vue'
import { upgradeDialog } from '@/helpers/upgradeDialog'

export default {
  components: {
    UpgradeBanner,
    NavbarApplication,
  },
  head() {
    return {
      bodyAttrs: {
        class: 'bg-base-100',
      },
    }
  },
  setup() {
    const footer = ref()
    const { height: footerHeight } = useElementSize(footer)
    const hasThreeColumnLayout = ref(true)

    const openUpgradeDialog = () => {
      upgradeDialog.open({
        tier: 100,
        reason: 'Dashboard navigation banner',
        subtitle: undefined,
        title: undefined,
        intentional: true,
      })
    }

    return { footer, footerHeight, hasThreeColumnLayout, openUpgradeDialog }
  },
  props: {
    headerSize: {
      type: String,
      default: 'small',
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    contentDirection: {
      type: String,
      default: 'col',
    },
    showBreadcrumb: {
      type: Boolean,
      default: false,
    },
    alertOnLeaveSite: {
      type: Boolean,
      default: false,
    },
    contentContainerClass: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.alertOnLeaveSite && !import.meta.env.DEV) {
      // Note that the message is ignored on most browsers due security concerns. But we should return a string anyway
      window.onbeforeunload = () => 'Leave site?'
    }
  },
  beforeUnmount() {
    window.onbeforeunload = null
  },
}
</script>

<style lang="scss">
#main-header {
}

.page {
  --footer-offset: var(--footer-height);

  &:has(.floating-footer-button) {
    --footer-offset: calc(var(--footer-height) + 5rem);
  }
}

footer:has(.floating-footer-button) {
  margin-top: 5rem;
}

.purple-bg {
  position: absolute;
  background: url('/images/svgs/app-bubbles.svg'), linear-gradient(256.79deg, #a000fe -12.32%, #6080fe 97.88%);
  width: 100%;
  height: 250px;
  overflow: hidden;

  @media (max-width: 767px) {
    background: #8833ff;
  }

  .wave {
    background-image: url('/images/svgs/hero-wave.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    height: 100%;

    width: 101%;
    bottom: -1px;
    background-size: 100%;
  }

  &.small {
    height: 64px;
  }

  &.medium {
    height: 350px;
  }

  &.large {
    height: 100vh;
  }
}
</style>
