<template>
  <div class="flex min-h-0 flex-col">
    <div class="flex flex-col gap-2 p-4">
      <div class="flex flex-wrap gap-2">
        <button
          v-for="filter in filters"
          :key="filter.key"
          @click="updateFilter(filter.key)"
          :class="{ 'btn-active !bg-zinc-200': selectedFilter === filter.key }"
          class="btn-ghost btn-sm btn bg-zinc-50 font-light hover:bg-zinc-100 active:bg-zinc-200"
        >
          {{ filter.name }}
        </button>
      </div>
      <Input
        type="text"
        class="input min-w-0 rounded font-light text-blue-900"
        ref="stickerTextInp"
        v-model="stickerText"
      />
    </div>

    <div
      class="grid min-h-0 w-full gap-2 overflow-auto p-4 notranslate"
      :class="hasThreeColumnLayout ? 'grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))]' : 'grid-cols-2 xl:grid-cols-3'"
    >
      <Tile :aspect=".6" v-if="selectedFilter === 'multiple'" @click="() => (isOpen = true)">
        <IconAdjustmentsHorizontal />
        <span>Edit socials</span>
      </Tile>
      <Tile v-else feature="upload-custom-stickers" :aspect=".6" @click="$emit('uploadSticker')">
        <IconSquareRoundedPlus />
        Upload sticker
      </Tile>
      <StickerWrapper
        v-for="sticker in stickers"
        :key="sticker.key"
        ref="stickerWrappers"
        :sticker="sticker"
        :is-multi-sticker="selectedFilter === 'multiple'"
        :has-editable-text="true"
        :is-new="isNewSticker(sticker)"
        :html-content="previewText"
        @stickerPicked="onStickerPicked(sticker)"
      />
    </div>

    <tw-base-dialog
      :model-value="isOpen"
      :closable="true"
      :styles="'max-w-screen-md'"
      @update:modelValue="(val) => (isOpen = val)"
    >
      <div class="px-8 pb-8">
        <h4>Manage your social icons</h4>
        <p class="text-opacity-50">Select which social platforms you wish to display in your sticker</p>
        <div class="mt-4 flex flex-col gap-2">
          <label class="flex items-center gap-2" v-for="social in socials" :key="social.key">
            <input type="checkbox" class="toggle-primary toggle" v-model="social.selected" />
            <span class="text-lg">{{ social.label }}</span>
          </label>
        </div>

        <div class="modal-action justify-between">
          <button class="btn-outline btn-primary btn" @click="isOpen = false">Cancel</button>

          <button
            @click="isOpen = false"
            class="hover:shadow-gradient bg-gradient btn relative gap-2 border-transparent font-bold text-white before:transition-all hover:border-transparent"
          >




            Save

            <IconArrowRight class="transition group-hover:translate-x-2" />
          </button>
        </div>
      </div>
    </tw-base-dialog>
  </div>
</template>

<script>
import stickerLibrary from './stickerLibrary/stickerLibrary'
import stickerService from '../../services/stickerService'
import StickerWrapper from './StickerWrapper.vue'
import { noop } from 'lodash-es'
import { IconAdjustmentsHorizontal, IconArrowRight, IconSquareRoundedPlus } from '@tabler/icons-vue'
import { mapState } from 'pinia'
import { persistSocialsToLocalStorage, useEditorStickersStore } from '@/store/editor/editorStickers'
import TwBaseDialog from '@/components/Dialog/TwBaseDialog.vue'
import Tile from '@/components/Tile.vue'
import { isNewSticker, markStickerAsUsed, newestStickerFirst } from '@/helpers/isNewOverlayElement'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { Input } from '@/components/ui/input'

export default {
  components: {
    Tile,
    Input,
    TwBaseDialog,
    StickerWrapper,
    IconAdjustmentsHorizontal,
    IconSquareRoundedPlus,
    IconArrowRight,
  },
  setup() {
    return {
      hasThreeColumnLayout: ref(true),
    }
  },
  data() {
    return {
      stickerText: '',
      previewText: '',
      selectedFilter: '',
      filters: [
        {
          key: 'twitch',
          name: 'Twitch',
        },
        {
          key: 'youtube',
          name: 'YouTube',
        },
        {
          key: 'kick',
          name: 'Kick',
        },
        {
          key: 'facebook',
          name: 'Facebook',
        },
        {
          key: 'multiple',
          name: 'Multiple socials',
        },
        {
          key: 'halloween',
          name: '🎃 Halloween',
        },
      ],
      showChangeStickerText: false,
      switchToCustomPanel: noop,
      isOpen: false,
      stickerLibrary: stickerLibrary.sort(newestStickerFirst),
    }
  },
  watch: {
    stickerText(newValue) {
      if (newValue.trim() !== '') {
        this.previewText = newValue.trim()
      }
      this.rescaleStickers()
    },
  },
  async created() {
    const clipSource = this.$route.params.source

    if (this.stickerText === '') {
      this.stickerText = stickerService.getInitialStickerText(clipSource)
    }

    this.filters = this.filters.filter((f) => this.stickerLibrary.some((s) => s.tags.includes(f.key)))

    if (this.selectedFilter === '') {
      const matchedFilter = this.filters.find((f) => clipSource?.includes(f.name))
      const storedFilter = localStorage.getItem('__sl_selected_sticker_filter')
      this.updateFilter(matchedFilter?.key ?? storedFilter ?? 'twitch')
    }

    persistSocialsToLocalStorage()
  },
  computed: {
    ...mapState(useEditorStickersStore, ['socials']),
    stickers() {
      return this.stickerLibrary.filter((elem) => elem.tags.includes(this.selectedFilter))
    },
  },
  methods: {
    isNewSticker,
    updateFilter(filter) {
      this.selectedFilter = filter ?? 'twitch'
      localStorage.setItem('__sl_selected_sticker_filter', filter)
    },
    rescaleStickers() {
      if (!this.$refs.stickerWrappers) {
        return
      }
      this.$refs.stickerWrappers.forEach((stickerWrapper) => {
        stickerWrapper.scaleSticker()
      })
    },
    onStickerPicked(sticker) {
      this.$emit('stickerPicked')
      markStickerAsUsed(sticker)
    },
  },
}
</script>

<style lang="scss" scoped>
.sticker-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.change-sticker-text-button {
  cursor: pointer;
  user-select: none;

  svg {
    transform: rotate(90deg);
  }

  &.open {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.sticker-filter {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;

  .filter {
    font-size: 0.8em;
    padding: 0.3em 0.8em;
    border: 2px solid rgb(71, 8, 158, 0.2);
    border-radius: 40px;
    color: rgb(71, 8, 158, 0.6);

    &.active {
      background-color: #33cff2;
      color: white;
      border-color: #33cff2;
    }
  }
}
</style>
