<script lang="ts" setup>
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import DashboardButton from '@/areas/dashboard/components/DashboardButton.vue'
import IconSaxTwitch from '@/components/Icons/iconsax/IconSaxTwitch.vue'
import { ref, onMounted, computed } from 'vue'
import ClipsDialog from '@/components/Dialog/ClipsDialog.vue'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useRoute, useRouter } from 'vue-router'
import { useClipFormManager } from '@/Hooks/clip-form/useClipFormManager'
import ClipGoatPromotionModal from '@/pages/modals/ClipGoatPromotionModal.vue'
import LocalClipDialog from '@/components-v2/modules/HomePage/LocalClipDialog.vue'
import unwrap from '@/helpers/unwrap'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'
import { Input } from "@/components/ui/input";
import ClipEditorMultiUploadDialog from '@/components/Dialog/MultiUploadDialog/ClipEditorMultiUploadDialog.vue'
import { useStartup } from '@/areas/editor/startup'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import RadixErrorDialog from '@/components/Dialog/RadixErrorDialog.vue'
import Spinner from '@/components/Icons/Spinner.vue'
import { Button } from '@/components/ui/button'

const clipUrl = ref<string>()
const { submitClipUrl, error, youtubeVideoId, downloadError } = useClipFormManager()

const loading = ref(false);

const openErrorDialog = computed({
  get: () => downloadError.value !== null,
  set: (value: boolean) => {
    if (!value) {
      downloadError.value = null
    }
  }
});

const openVideoBlockedErrorDialog = computed({
  get: () => !!downloadError.value?.includes('blocked_video'),
  set: (value: boolean) => {
    if (!value) {
      downloadError.value = null
    }
  }
});

const dialog = ref<ClipsDialog>()
const resetEditor = useResetEditor()
const { startupFromTwitchClipByClipId } = useStartup()
async function onClipSelected(clipId: string) {
  resetEditor()
  await startupFromTwitchClipByClipId(clipId)
}

const Query = {
  // Clip URL is given by WebFlow.
  CLIP_URL: 'clipUrl',
  // Search clips from Twitch is given by WebFlow
  SEARCH_FROM_TWITCH: 'searchFromTwitch',
  // Upload local file button is clicked on WebFlow.
  LOCAL_FILE: 'localFile',
}

const localClipDialog = ref(false)

function openClipsDialog() {
  if (dialog.value) {
    dialog.value.open()
  }
}

const route = useRoute()
onMounted(() => {

  const queryKeys = unwrap.keys(route.query) as (typeof Query)[keyof typeof Query][]
  const clipUrlQuery = route.query[Query.CLIP_URL] as string | undefined

  if (clipUrlQuery) {
    submitClipUrl(clipUrlQuery)
  } else if (queryKeys.includes(Query.SEARCH_FROM_TWITCH)) {
    openClipsDialog()
  } else if (queryKeys.includes(Query.LOCAL_FILE)) {
    localClipDialog.value = true
  }
})

const handleSubmitClipUrl = async (clipUrl: string | undefined) => {
  loading.value = true
  await submitClipUrl(clipUrl)
  loading.value = false
}
</script>

<template>
  <section class="flex flex-col gap-4 light">
    <Input
      as="form"
      class="w-full relative p-0 border-none"
      :class="{ 'outline outline-2 outline-offset-1 outline-rose-500': error }"
      @submit.prevent.stop="handleSubmitClipUrl(clipUrl)"
    >
      <div class="shrink-0 pr-0 absolute left-3 top-2.5 text-rose-500" v-if="error">
        <IconSaxInfoCircle />
      </div>
      <input
        v-model="clipUrl"
        class="rounded-r-none bg-transparent min-w-0 flex-1 h-full px-3"
        :class="{ 'pl-11 text-rose-500': error }"
        placeholder="Paste the URL of a Twitch, YouTube, or Kick clip here"
        type="text"
      />
      <button
        class="group flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-l-none rounded-r-md bg-emerald-500 px-6 py-3 text-surface-inverse transition-colors"
        :class="{ 'pointer-events-none': loading }"
        type="submit"
      >
        <template v-if="loading">
          <span class="hidden md:inline">Loading..</span>
          <Spinner class="h-4 w-4 animate-spin mr-2.5" />
        </template>
        <template v-else>
          <span class="hidden md:inline">Get clip</span>
          <IconSaxArrowRight2 class="h-4 w-4 transition-transform group-hover:translate-x-2 md:mr-2" />
        </template>
      </button>
    </Input>

    <ClipUrlError :error="error" />

    <div class="flex flex-wrap gap-2">
      <ClipEditorMultiUploadDialog>
        <DashboardButton class="flex-auto">
          <IconSaxDocumentUpload />
          Upload from computer
        </DashboardButton>
      </ClipEditorMultiUploadDialog>
      <DashboardButton class="flex-auto" @click="openClipsDialog">
        <IconSaxTwitch />
        Upload from Twitch
      </DashboardButton>
    </div>
    <ClipsDialog ref="dialog" @clipSelected="(clip) => onClipSelected(clip.id)" />

    <RadixErrorDialog v-model:open="openErrorDialog">
      <template #title>Something went wrong</template>
      <template #description>
        <div class="flex flex-col gap-4">
          <span>Unfortunately, your clip could not be found. Your clip might still be processing which can take up to 24 hours after creation 🙊. </span>
        </div>
      </template>
      <template #actions>
        <Button variant="primary" @click="openErrorDialog = false">Change clip</Button>
      </template>
    </RadixErrorDialog>

    <RadixErrorDialog v-model:open="openVideoBlockedErrorDialog">
      <template #title>Region blocked 🌎</template>
      <template #description>
        Unfortunately, this clip has been blocked in the USA based on copyright grounds. Please try another clip or use the 'Upload from Twitch' button.
      </template>
      <template #actions>
        <Button variant="primary" @click="openVideoBlockedErrorDialog = false">Change clip</Button>
      </template>
    </RadixErrorDialog>

    <teleport to="body">
      <LocalClipDialog v-model="localClipDialog" />
    </teleport>
    <ClipGoatPromotionModal v-model="youtubeVideoId" />
  </section>
</template>

<style lang="scss" scoped>
input:placeholder-shown {
  text-overflow: ellipsis;
}
</style>
