<script lang="ts" setup>
import { computed, provide, ref, defineAsyncComponent } from 'vue'
import { useElementSize } from '@vueuse/core'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import IconSaxSetting2 from '@/components/Icons/iconsax/IconSaxSetting2.vue'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import StickerMovableElement from '@/components/Editor/Movable/StickerMovableElement.vue'
import MovableRoot from '@/modules/SLMovable/MovableRoot.vue'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { onUserInfoReadyAsync } from '@/store/user/userInfo'
import templateService from '@/services/templateService'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { useRoute } from 'vue-router'
import { useUserTemplatesStore } from '@/store/user/userTemplates'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import type { LayoutKey } from '@/data/layoutData'
import { orderBy } from 'lodash-es'
import WebcodecRendererFeedLayer from '@/components/Editor/WebcodecRendererFeedLayer.vue'
import TikTokSafeZone from '@/components/Editor/SafeZone/TikTokSafeZone.vue'
import YoutubeSafeZone from '@/components/Editor/SafeZone/YoutubeSafeZone.vue'
import InstagramSafeZone from "@/components/Editor/SafeZone/InstagramSafeZone.vue";
import CaptionsMovableElement from '@/components/Editor/Movable/CaptionsMovableElement.vue'

const PreviewVideoRenderer = defineAsyncComponent(() => import('@/components/Editor/PreviewVideoRenderer.vue'))

const props = defineProps<{
  enableSnapping: boolean
  safeZones: [{ key: 'youtube'; show: boolean }, { key: 'tiktok'; show: boolean }, { key: 'instagram'; show: boolean }]
}>()

const emit = defineEmits<{
  (event: 'advancedSettings'): void
}>()

const editorWrapper = ref<HTMLElement | null>(null)
const phoneWrapper = ref<HTMLElement | null>(null)
provide('editorWrapper', editorWrapper);

const { width: editorWidth, height: editorHeight } = useElementSize(editorWrapper)
const { width: phoneWidth, height: phoneHeight } = useElementSize(phoneWrapper)

const showYoutubeSafeZone = computed(() => props.safeZones.find((zone) => zone.key === 'youtube')?.show)
const showTiktokSafeZone = computed(() => props.safeZones.find((zone) => zone.key === 'tiktok')?.show)
const showInstagramSafeZone = computed(() =>  props.safeZones.find((zone) => zone.key === 'instagram')?.show)

const editorFocusStore = useEditorFocusStore()

document.body.addEventListener('click', (e) => {
  if (!e.target?.matches('[data-retain-focus], [data-retain-focus] *')) {
    editorFocusStore.unFocus()
  }
})

const hasThreeColumnLayout = ref(true)

const stickersStore = useStickersStore()

const editorMainStore = useEditorMainStore()
const route = useRoute()

async function onVideoLoaded(videoElement: HTMLVideoElement) {

  const templatesStore = useUserTemplatesStore()
  const editorFeedDataStore = useEditorFeedDataStore()
  const layoutsStore = useLayoutsStore()
  const customLayout = layoutsStore.selectById(editorMainStore.layoutId)

  if (editorMainStore.templateId || route.query.savedTemplate) {

    await onUserInfoReadyAsync()

    // Await promise in getTemplates. Will not do additional request.
    if (templatesStore.isLoading) {
      await templateService.getTemplates()
    }

    const template = templatesStore.savedTemplates.find((x) => x.id == editorMainStore.templateId) as Template | undefined

    if (!template) {
      return EventBus.$emit(mainEvents.ERROR, 'We encountered an issue while loading your template. Please try again '
        + 'later or contact support if the issue persists.')
    }

    editorFeedDataStore.initializeForTemplateFeeds(
      template,
      videoElement.videoWidth, videoElement.videoHeight,
      editorWidth.value, editorHeight.value
    )
  } else if (customLayout) {
    editorFeedDataStore.initializeForCustomLayout(customLayout.id)
  } else {
    editorFeedDataStore.initializeForFeed(
      editorMainStore.layoutName as LayoutKey,
      videoElement.videoWidth, videoElement.videoHeight,
      editorWidth.value, editorHeight.value
    )
  }
}

// function surfaceArea(sticker: Sticker) {
//   return sticker.area.width * editorWidth.value * sticker.area.height * editorHeight.value
// }

const sortedStickers = computed(() => {
  // return orderBy(stickersStore.entities, surfaceArea, 'desc')
  return orderBy(stickersStore.entities, 'z', 'asc')
})
</script>

<template>
  <div
    class="rounded-lg"
    :class="
      hasThreeColumnLayout
        ? 'preview-container flex h-full min-h-0 flex-col gap-2 border-2 border-gray-100 bg-white p-4'
        : 'border-10 border-gray-900 bg-gray-900'
    "
    :style="`--phone-width: ${phoneWidth}px; --phone-height: ${phoneHeight}px;`"
  >
    <!--    <iphone14 class="mx-auto" :inner-width="editorWidth">-->
    <div
      ref="phoneWrapper"
      :class="{ 'contain-box grid h-full min-h-0 w-full flex-auto place-items-center': hasThreeColumnLayout }"
    >
      <div
        ref="editorWrapper" id="preview-container"
        class="editor-wrapper relative z-10 notranslate"
        :class="hasThreeColumnLayout
          ? 'contain border-gray-900'
          : 'h-[384px] w-[219px] lg:h-[480px] lg:w-[286px] 2xl:h-[568px] 2xl:w-[320px]'"
      >
        <PreviewVideoRenderer class="h-full w-full col-start-1 row-start-1 rounded overflow-hidden" @video-loaded="onVideoLoaded" :background="editorMainStore.layoutHasBlurredBackground" />
        <MovableRoot class="h-full w-full col-start-1 row-start-1">
          <WebcodecRendererFeedLayer :container-width="editorWidth" :container-height="editorHeight" />
          <div id="sticker-layer" class="absolute inset-0 rounded overflow-hidden" />
          <CaptionsMovableElement :snapping-enabled="enableSnapping" />
          <StickerMovableElement v-for="sticker in sortedStickers" :key="sticker.id" :id="sticker.id" :snapping-enabled="enableSnapping" />
        </MovableRoot>
        <TikTokSafeZone class="h-full w-full col-start-1 row-start-1" v-show="showTiktokSafeZone" />
        <YoutubeSafeZone class="h-full w-full col-start-1 row-start-1" v-show="showYoutubeSafeZone" />
        <InstagramSafeZone class="h-full w-full col-start-1 row-start-1" v-show="showInstagramSafeZone" />
      </div>
    </div>

    <button
      v-if="hasThreeColumnLayout"
      class="btn inline-flex w-full gap-2 rounded border-transparent bg-transparent text-sm font-semibold text-gray-600 hover:border-transparent hover:bg-gray-100"
      @click="emit('advancedSettings')"
    >
      <IconSaxSetting2 />
      Advanced Settings
    </button>
  </div>
</template>

<style scoped>
:root {
  --phone-width: 1080px;
  --phone-height: 1920px;
}

.editor-wrapper {
  position: relative;
  border-radius: 0.5rem;
  display: grid;
}

.preview-container {
  max-height: min(100%, calc(calc(var(--phone-width) * calc(16 / 10))) + 9rem);
}

/* Prevent border from interfering width `.contain` aspect ratio */
.contain-box {
  --border-width: theme('spacing.2');
  padding: var(--border-width);
}

.contain {
  box-sizing: content-box;
  border-width: var(--border-width);
  margin: calc(-1 * var(--border-width));

  --scale: min(calc(var(--phone-width) / 1080), calc(var(--phone-height) / 1920));
  --width: calc(1080 * var(--scale));
  --height: calc(1920 * var(--scale));
  width: var(--width);
  height: var(--height);
}
</style>
